//mycart
.mycart {
  .mycart-head {
    border-bottom: 1px solid #6d6d6d33;
    padding-bottom: 10px;
    margin-bottom: 10px;
    h2 {
      color: #000000;
      font-family: "gothammedium";
      font-size: 24px;
      margin: 0px;
    }
  }
  .mycart-mid {
  }
  .mycart-detail-item {
    margin-bottom: 10px;
    .mycart-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0px;
      .mycart-left {
        h3 {
          color: #000000;
          font-family: "gothammedium";
          font-size: 16px;
          margin: 0px;
          &.total-heading {
            color: #4c78ff;
          }
        }
        p {
          color: #141516;
          font-size: 13px;
          margin: 0px;
          max-width: 400px;
          line-height: 16px;
          font-family: "gothammedium";
          display: flex;
          align-items: center;
          a {
            &.info-icon {
              margin-left: 4px;
              margin-bottom: -3px;
              ion-icon {
                color: #8a8888;
                font-size: 20px;
                line-height: 20px;
              }
            }
          }
          ion-toggle {
            margin-left: 10px;
          }
        }
      }
      .mycart-right {
        .mycart-price {
          color: #000000;
          font-family: "gothammedium";
          font-size: 16px;
          margin: 0px;
          &.total-price {
            color: #4c78ff;
          }
        }
        .deleteIcon {
          a {
            ion-icon {
              color: #4c78ff;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .mycart-bottom {
    padding: 0px;
  }
}
