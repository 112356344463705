.thankYou-page {
  padding-top: 50px;
  .theme-card {
    margin-bottom: 20px;
    .card-body {
      padding: 10px;
    }
  }

  //thankYou-header
  .thankYou-header {
    margin-bottom: 40px;
    h2 {
      color: #000000;
      font-family: "gothammedium";
    }
    p {
      color: #141516;
      font-size: 13px;
      margin: 0px;
      max-width: 400px;
      line-height: 16px;
      font-family: "gothambold";
      &.orderNumber {
        font-weight: 600;
        margin-bottom: 10px;
      }
      span {
        color: #4c78ff;
        margin-left: 5px;
      }
    }
  }

  //orderflow
  .orderflow {
    border-top: 1px solid #8b8ba980;
    padding-top: 10px;
    .orderflow-inline {
      display: flex;
      .orderflow-item {
        width: 30%;
        padding: 0px 20px;
        .orderflow-item-top {
          margin-bottom: 10px;
          h3 {
            color: #000000;
            font-size: 14px;
            margin: 0px;
            line-height: 16px;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-family: "gothambook";
          }
        }
        .orderflow-item-detail {
          p {
            color: #000000;
            font-size: 13px;
            margin: 0px;
            font-weight: 400;
            line-height: 16px;
            font-family: "gothammedium";
          }
        }
      }
    }
  }

  //thankyou-info
  .thankyou-info {
    &.methodBox {
      .shippingMethod-item {
        background-color: #fff;
        margin-bottom: 10px;
        .shippingMethod-left {
          h3 {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  //thankyou-img
  .thankyou-img {
    padding: 30px 0px;
    ion-img {
      width: 100%;
      max-width: 300px;
      margin: 0px auto;
    }
  }

  //mycart
  .mycart {
    .mycart-header {
      margin-bottom: 20px;
      h3 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 16px;
        margin: 0px;
      }
    }
    .theme-card {
      .card-body {
        background: #f7f8fb;
        border-radius: 6px;
      }
    }
  }
}

//Responsive sCSS
@media only screen and (max-width: 767px) {
  .thankYou-page {
    padding: 30px 0px;
    ion-grid {
      ion-row {
        padding-left: 0px;
        padding-right: 0px;
        ion-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
    //orderflow
    .orderflow {
      .orderflow-inline {
        flex-wrap: wrap;
        .orderflow-item {
          width: 100%;
          margin-bottom: 15px;
          padding: 0px 0px;
          .orderflow-item-top {
            margin-bottom: 3px;
          }
        }
      }
    }
    //thankyou-img
    .thankyou-img {
      display: none;
    }
  }
}
