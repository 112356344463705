ion-footer {
  background-color: #fff;
  padding: 40px 0px 15px;
  &:before {
    background-image: none !important;
  }

  //footer
  .footer {
    .footer-logo {
      margin-bottom: 20px;
      ion-img {
        width: 100%;
        max-width: 200px;
      }
    }
    .footer-detail {
      .footer-detail-inner {
        .footer-detail-item {
          margin-bottom: 15px;
          &:nth-last-child(1) {
            margin-bottom: 0px;
          }
          h5 {
            color: #000000;
            font-family: "gothammedium";
            margin: 0px 0px 5px;
            font-size: 15px;
          }
          p {
            color: #000000;
            font-size: 12px;
            margin: 0px 0px 5px;
            line-height: 16px;
            font-weight: 400;
            font-family: "gothammedium";
          }
        }
      }
    }

    .graphics-img {
      display: flex;
      flex-flow: column;
      align-items: center;
      a {
        ion-img {
          width: 100%;
          max-width: 150px;
          margin-bottom: 10px;
        }
        &:nth-last-child(1) {
          ion-img {
            margin-bottom: 0px;
            width: 80px;
          }
        }
      }
    }

    .footer-cclogos-img{
      ion-img{
        max-width: 250px;
      }
    }
  }

  .footer-nav {
    padding: 0px 10px;
    a {
      color: #4c78ff;
      font-size: 12px;
      margin: 0px 20px 0px 0px;
      line-height: 16px;
      font-weight: 400;
      font-family: "gothammedium";
    }
  }

  .footer-bottom {
    text-align: left;
    padding: 8px 10px;
    p {
      color: #6d6d6d;
      font-size: 10px;
      margin: 0px;
      line-height: 16px;
      font-weight: 400;
      font-family: "gothammedium";
      padding: 0px 10px 0px 0px;
    }
  }

  .align-center {
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  ion-footer {
    ion-grid {
      ion-row {
        padding-left: 0px;
        padding-right: 0px;
        ion-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
    //footer
    .footer {
      .footer-nav {
        a {
          margin: 0px 18px 0px 0px;
        }
      }
      .graphics-img {
        display: none;
      }
    }
  }
}
