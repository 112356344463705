.covid-vaccine-page {
  background-color: #fff;
  line-height: 16px;
  font-weight: 400;
  font-family: "gothammedium";

  p, li {
    line-height: 25px;
  }

  h3 {
    color: #000000;
    font-family: gothammedium;
    font-style: normal;
    font-weight: 500;
  }

  .top-banner {
    padding: 40px 0;
    background-color: #fff;
    .banner-detail {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      border-radius: 20px;
      .left-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 70px 50px;
        position: static;
        height: 430px;
        background: #4C78FF;
        border-radius: 20px 0 0 20px;
        flex: none;
        flex-grow: 0;
        margin: 0;

        h3 {
          font-family: "gothammedium";
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 40px;
          color: #FFFFFF;
          flex: none;
          flex-grow: 0;
          margin: 0;
        }
        .desc {
          font-family: gothammedium;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 30px;
          color: #FFFFFF;
          flex: none;
          flex-grow: 0;
          margin: 50px 0;
        }
        .schedule-button {
          width: 345px;
          height: 50px;
          background: #FFFFFF;
          border-radius: 8px;
          font-family: gothammedium;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #4268DC;
        }
      }
      .right-content {
        flex-grow: 0;
        margin: 0 0;
        .banner-img {
          height: 430px;
        }
      }
    }
    .notes {
      margin-top: 10px;
      text-align: center;
      .content {
        color: #141516;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .schedule {
    .schedule-title {
      margin-bottom: 70px;
      font-family: gothammedium;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
    }
    .left-content {
      img {
        width: 324px;
        height: 341px;
      }
    }
    .right-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      position: static;
      flex: none;
      flex-grow: 0;
      font-family: gothammedium;
      font-style: normal;
      font-weight: normal;
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        flex: none;
        flex-grow: 0;
        margin: 30px 0;
      }
      ul {
        padding-left: 0;
        list-style: none;
        li {
          color: #000000;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 25px;
          display: flex;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0 10px;
          img {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            margin-top: 5px;
          }
          &::marker {
            color: #4C78FF;
          }
        }
      }
      a {
        color: #4C78FF;
        margin-bottom: 60px;
      }
    }
  }
  .get-vaccine {
    background-color: rgba(241, 243, 248, 0.6);
    color: #000000;
    .get-covid-header {
      text-align: center;
      color: #000000;
      margin-top: 50px;
      padding: 0 70px;
      h3 {
        font-family: gothammedium;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 25px;
        margin-top: 85px;
      }
      p {
        font-family: gothammedium;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        margin-top: 39px;
        margin-bottom: 61px;
        max-width: 800px;
        text-align: initial;
        margin-left: 50px;
      }
    }
    .image-wrapper {
      width: 300px;
      height: 300px;
      background: #EEF2FF;
      border-radius: 50%;
      text-align: center;
      padding: 15%;
    }
    .detail {
      width: 300px;
      text-align: center;
      .schedule-button {
        background: #4C78FF;
        border-radius: 8px;
        font-family: gothammedium;
        font-style: normal;
        padding: 10px 25px;
        font-weight: 500;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .get-covid-footer {
      margin: 97px auto 61px;
      max-width: 800px;
      p {
        font-family: gothammedium;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        color: #000000;
      }
    }
  }
  .covid-footer {
    padding: 70px 0;
    text-align: center;
    h3 {
      margin-bottom: 50px;
    }
    .signup-button {
      background: #4C78FF;
      border-radius: 8px;
      font-family: gothammedium;
      font-style: normal;
      padding: 10px 25px;
      font-weight: 500;
      text-align: center;
      color: #FFFFFF;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
