.checkoutDelivery-page {
  padding-top: 50px;
  .theme-card {
    margin-bottom: 20px;
  }
  //parentform-button
  .parentform-button {
    .theme-btn {
      display: block;
      max-width: 250px;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .checkoutDelivery-page {
    padding: 30px 0px;
    ion-grid {
      ion-row {
        padding-left: 0px;
        padding-right: 0px;
        ion-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}
