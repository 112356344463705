.checkoutPayment-page {
  padding-top: 50px;
  .theme-card {
    margin-bottom: 20px;
  }

  //shippingMethod-item-scroll
  .shippingMethod-item-scroll {
    // overflow: auto;
    // max-height: 200px;
    // margin-bottom: 15px;
    .shippingMethod-more {
      margin-bottom: 15px;
      .theme-btn {
        width: 100%;
      }
    }
  }

  //billingAddress-info
  .billingAddress-info {
    .billingAddress-info-top {
      margin-bottom: 15px;
      h3 {
        color: #000000;
        font-size: 13px;
        margin: 0px;
        max-width: 500px;
        line-height: 16px;
        font-family: "gothammedium";
        display: flex;
        align-items: center;
      }
    }
    .billingAddress-info-body {
      border: 1px solid #8b8ba94d;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 15px;
      .billingAddress-detail {
        ion-label {
          color: #8b8ba9;
          font-size: 13px;
          margin: 0px 0px 5px 0px;
          line-height: 16px;
          display: block;
          font-family: "gothammedium";
        }
        p {
          color: #000000;
          font-size: 13px;
          margin: 0px;
          max-width: 180px;
          line-height: 16px;
          font-family: "gothammedium";
        }
      }
    }
  }
  //parentform-button
  .parentform-button {
    .theme-btn {
      display: block;
      max-width: 250px;
      margin-bottom: 10px;
    }
  }
}

//Responsive scss
@media only screen and (max-width: 767px) {
  .checkoutPayment-page {
    padding: 30px 0px;
    ion-grid {
      ion-row {
        padding-left: 0px;
        padding-right: 0px;
        ion-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}
