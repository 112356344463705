//fonts
@font-face {
  font-family: "gothambold";
  src: url("fonts/gothambold-webfont.woff2") format("woff2"),
    url("fonts/gothambold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold_italic";
  src: url("fonts/gothambolditalic-webfont.woff2") format("woff2"),
    url("fonts/gothambolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("fonts/gothambook-webfont.woff2") format("woff2"),
    url("fonts/gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook_italic";
  src: url("fonts/gothambookitalic-webfont.woff2") format("woff2"),
    url("fonts/gothambookitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight";
  src: url("fonts/gothamlight-webfont.woff2") format("woff2"),
    url("fonts/gothamlight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight_italic";
  src: url("fonts/gothamlightitalic-webfont.woff2") format("woff2"),
    url("fonts/gothamlightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("fonts/gothammedium-webfont.woff2") format("woff2"),
    url("fonts/gothammedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

//scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #eef2ff;
}

::-webkit-scrollbar-thumb {
  background: #4c78ff;
}

//color
.orange {
  color: #efb55d;
}
.green {
  color: #2ed5a3;
}
.blue {
  color: #4268dc;
}

//bg colors
.bg-light-blue {
  background-color: #eef2ff;
}

.bg-light-green {
  background-color: #76f9d2;
}

.bg-white {
  background-color: #fff;
}

.bg-light-grey {
  background-color: #f7f8fb;
}

.bg-dark-grey {
  background-color: #c4c4c433;
}

//default
ion-content {
  --background: #f7f8fb;
}

a {
  text-decoration: none;
  cursor: pointer;
}

p {
  color: #141516;
}

ion-icon {
  pointer-events: none;
}

//pedding css
.pt-0 {
  padding-top: 0px !important;
}

//Theme buttons
ion-button {
  cursor: pointer;
}

.theme-btn {
  font-size: 14px;
  border: none;
  outline: none;
  --border-radius: 6px;
  cursor: pointer;
  --box-shadow: none;
  line-height: normal;
  font-weight: 500;
  --padding-bottom: 10px;
  --padding-end: 25px;
  --padding-start: 25px;
  --padding-top: 10px;
  font-family: "gothammedium";
  span {
    display: flex;
    align-items: center;
  }
  ion-spinner {
    width: 15px;
  }
  ion-icon {
    font-size: 18px;
    margin-right: 5px;
  }
  &.button-grey {
    --background: #4c78ff1a;
    --background-activated: #4c78ff1a;
    text-transform: initial;
    span {
      color: #4c78ff;
    }
    ion-spinner {
      color: #4c78ff;
    }
  }
  &.button-blue {
    --background: #4c78ff;
    --background-activated: #4c78ff;
    text-transform: initial;
    span {
      color: #fff;
    }
    ion-spinner {
      color: #fff;
    }
  }
  &.button-blue-outline {
    --background: #fff;
    --background-activated: #fff;
    text-transform: initial;
    border: 1px solid #4c78ff;
    border-radius: 6px;
    span {
      color: #4c78ff;
    }
    ion-spinner {
      color: #4c78ff;
    }
  }
  &.button-dark-grey {
    --background: #a9a9c3;
    --background-activated: #a9a9c3;
    text-transform: initial;
    span {
      color: #fff;
    }
    ion-spinner {
      color: #fff;
    }
  }
  &.button-white {
    --background: #fff;
    --background-activated: #fff;
    text-transform: initial;
    span {
      color: #4c78ff;
    }
    ion-spinner {
      color: #4c78ff;
    }
  }
}

//theme-icon-btn
.theme-icon-btn {
  font-size: 14px;
  border: none;
  outline: none;
  --border-radius: 6px;
  cursor: pointer;
  --box-shadow: none;
  line-height: normal;
  //font-weight: 500;
  --padding-bottom: 6px;
  --padding-end: 6px;
  --padding-start: 6px;
  --padding-top: 6px;
  height: auto;
  font-family: "gothammedium";
  ion-spinner {
    width: 15px;
  }
  &.button-blue-icon {
    --background: #4c78ff;
    --background-activated: #4c78ff;
    text-transform: initial;
    ion-icon {
      color: #fff;
    }
    ion-spinner {
      color: #fff;
    }
  }
  &.button-dark-grey-icon {
    --background: #8b8ba9;
    --background-activated: #8b8ba9;
    text-transform: initial;
    ion-icon {
      color: #fff;
    }
    ion-spinner {
      color: #fff;
    }
  }
}

//checkbox
.round-checkbox {
  margin-right: 10px;
  --background: #fff;
  --border-color: #4c78ff;
  --border-radius: 100%;
  --background-checked: #4c78ff;
  min-width: 18px;
}

//theme-card-parent
.theme-card-parent {
  .theme-card-parent-heading {
    margin-bottom: 15px;
    h3 {
      color: #000000;
      font-family: "gothammedium";
      font-size: 16px;
      margin: 0px;
    }
  }
}

//card
.theme-card {
  background-color: #fff;
  border-radius: 10px;
  .card-header {
    padding: 25px 25px 0px;
  }
  .card-body {
    padding: 25px;
  }
  .card-footer {
  }
}

//list
ion-list {
  background: transparent !important;
  padding-top: 0px;
  padding-bottom: 0px;
  ion-item {
    --background: transparent;
    --border-color: transparent;
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --min-height: auto;
  }
}

//parentform
.parentform {
  .parentform-heading {
    margin-bottom: 20px;
    span {
      color: #6d6d6d;
      font-size: 13px;
      display: block;
      max-width: 500px;
      font-family: "gothammedium";
    }
    h2 {
      color: #000000;
      font-family: "gothammedium";
    }
    p {
      color: #141516;
      font-size: 13px;
      margin: 0px;
      max-width: 400px;
      line-height: 16px;
      font-family: "gothammedium";
    }
    .cclogos-img {
      max-width: 250px;
      ion-img{
        width: 100%
      }
    }
  }
  .parentform-left-detail {
    .parentform-left-content {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      span {
        display: block;
        background-color: #4c78ff;
        min-width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        ion-img {
          width: 14px;
        }
      }
      p {
        color: #000000;
        font-size: 13px;
        margin: 0px;
        max-width: 350px;
        line-height: 16px;
        font-weight: 400;
        font-family: "gothammedium";
      }
    }
  }

  .parentform-left-btn {
    ion-button {
      width: 100%;
      max-width: 350px;
    }
  }

  .parentform-right {
    .parentform-check {
      display: flex;
      align-items: end;
      margin-bottom: 20px;
      ion-checkbox {
        margin-right: 10px;
        --background: #fff;
        --border-color: #4c78ff;
        --border-radius: 100%;
        --background-checked: #4c78ff;
        min-width: 18px;
        width: 18px;
        height: 18px;
      }
      p {
        color: #000000;
        font-size: 13px;
        margin: 0px;
        max-width: 500px;
        line-height: 16px;
        font-family: "gothammedium";
        a {
          color: #4c78ff;
        }
      }
    }
    .parentform-button {
      .theme-btn {
        width: 100%;
      }
    }
    .have-account-text {
      text-align: center;
      margin-top: 5px;
      p {
        color: #000000;
        font-size: 13px;
        margin: 0px auto;
        max-width: 500px;
        line-height: 16px;
        font-family: "gothammedium";
        a {
          color: #4c78ff;
        }
      }
    }
  }
}

//form
.form-group {
  margin-bottom: 20px;
  ion-label {
    color: #8b8ba9;
    font-size: 13px;
    margin: 0px 0px 5px 0px;
    line-height: 16px;
    display: block;
    font-family: "gothammedium";
  }
  //inline-label
  .inline-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ion-label {
    }
    .inline-link {
      color: #4c78ff;
      font-size: 13px;
      margin: 0px 0px 5px 0px;
      line-height: 16px;
      display: block;
      font-family: "gothammedium";
    }
  }
  .form-control {
    --color: #000;
    font-size: 13px;
    width: 100%;
    border: 1px solid rgba(139, 139, 169, 0.3);
    border-radius: 4px;
    --padding-end: 10px;
    --padding-start: 10px;
    font-family: "gothamlight";
    font-weight: 600;
    --placeholder-color: #c0c1d4;
    color: #000;
  }
  .form-link {
    margin-top: 15px;
    a {
      font-size: 15px;
      font-family: "gothammedium";
      color: #4c78ff;
    }
  }

  //icon-input
  .icon-input {
    position: relative;
    .form-control {
      --padding-end: 30px;
    }
    a {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
      ion-icon {
        color: #8b8ba9;
        font-size: 16px;
      }
      ion-spinner {
        width: 18px;
        height: 18px;
      }
    }
  }

  //left-icon-input
  .left-icon-input {
    position: relative;
    .form-control {
      --padding-start: 35px;
    }
    a {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 999;
      ion-icon {
        color: #8b8ba9;
        font-size: 16px;
      }
      ion-spinner {
        width: 15px;
        height: 15px;
        color: #4c78ff;
      }
    }
  }

  //dateicon-input
  .dateicon-input {
    position: relative;
    ion-datetime {
      --placeholder-color: #a9a9c3;
      --padding-end: 30px;
      color: #000;
    }
    a {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 999;
      ion-icon {
        color: #8b8ba9;
        font-size: 16px;
      }
    }
  }

  ion-radio-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .radio-item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      ion-label {
        color: #000;
        margin: 0px;
        line-height: normal;
      }
      ion-radio {
        --color: #4c78ff;
        --color-checked: #4c78ff;
        margin-right: 5px;
        width: 21px;
        height: 21px;
      }
    }
  }

  //select-input
  .select-input {
    position: relative;
    .form-control {
      --padding-end: 10px;
      color: #000;
      --padding-top: 7px;
      --padding-bottom: 8px;
      &::part(icon) {
        opacity: 0;
      }
    }
    a {
      position: absolute;
      right: 10px;
      top: 11px;
      z-index: 1;
      background-color: #fff;
      ion-icon {
        color: #8b8ba9;
        font-size: 16px;
      }
    }
  }
}

//select-popover
.select-popover {
  .popover-wrapper {
    .popover-content {
      ion-select-popover {
        ion-list {
          ion-radio-group {
            ion-item {
              --padding-start: 10px;
              --padding-end: 10px;
            }
          }
        }
      }
    }
  }
}

// search-drop css
.search-drop {
  position: relative;
  .search-drop-list {
    background-color: #fff;
    box-shadow: 0px 3px 25px #00000029;
    padding: 10px;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    top: 60px;
    .phone-or-email {
      display: contents;
    }
    a {
      color: #4c78ff;
      font-size: 13px;
      line-height: 16px;
      font-family: "gothammedium";
      display: block;
      margin-bottom: 6px;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
  }
}

//text css
.text-grey {
  color: #8b8ba9;
  font-size: 11px;
  margin: 4px 0px 0px;
  max-width: 500px;
  line-height: 16px;
  font-family: "gothammedium";
  display: flex;
  align-items: center;
  ion-icon {
    font-size: 18px;
  }
}

.text-blue {
  color: #4c78ff;
}

.errormsg {
  color: red;
  font-size: 11px;
  margin: 4px 0px 0px;
  max-width: 500px;
  line-height: 16px;
  font-family: "gothammedium";
  display: flex;
  align-items: center;
  ion-icon {
    font-size: 18px;
  }
}

.successmsg {
  color: green;
  font-size: 11px;
  margin: 4px 0px 0px;
  max-width: 500px;
  line-height: 16px;
  font-family: "gothammedium";
  display: flex;
  align-items: center;
  ion-icon {
    font-size: 18px;
  }
}

//container
.space-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .space-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .space-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .space-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .space-container {
    max-width: 1140px;
  }
}

//page card height
.pagecard-height {
  .theme-card {
    min-height: calc(100vh - 200px);
  }
}

//notification-bar
.notification-bar {
  background-color: #76f9d2;
  padding: 8px 0px;
  p {
    color: #141516;
    font-size: 16px;
    margin: 0px;
    line-height: 18px;
    font-family: "gothammedium";
    text-align: center;
    span {
      vertical-align: middle;
      ion-icon {
        color: #4268dc;
        font-size: 22px;
        margin-right: 4px;
      }
      a {
        color: #4268dc;
      }
    }
  }
}

// padding css
.p-0 {
  padding: 0px;
}

//checkbox css
.checkbox-round-blue {
  --background: #fff;
  --border-color: #4c78ff;
  --border-radius: 100%;
}

//forgot flow
.forgotflow {
  .login-page-inner {
    max-width: 800px;
    margin: 50px auto;
    .theme-card {
      .card-body {
        .parentform {
          .signin-left-img {
            // margin-bottom: -35px;
            // margin-left: -35px;
            ion-img {
              width: 100%;
            }
          }
          .parentform-right {
            .parentform-right-head {
              text-align: center;
              margin-bottom: 15px;
              h2 {
                color: #000000;
                font-family: "gothammedium";
                font-size: 25px;
                margin: 0px 0px 5px 0px;
              }
              p {
                // color: #8b8ba9;
                color: #000;
                font-size: 13px;
                margin: 0px 0px 5px 0px;
                line-height: 16px;
                display: block;
                font-family: "gothammedium";
              }
            }
            //message-text
            .message-text {
              margin-bottom: 10px;
              p {
                font-size: 12px;
              }
            }
            //ortag
            .ortag {
              text-align: center;
              margin-bottom: 10px;
              p {
                color: #000;
                font-size: 13px;
                margin: 0px 0px 5px 0px;
                line-height: 16px;
                display: block;
                font-family: "gothammedium";
              }
            }
            .parentform-button {
              .button-blue-outline {
                span {
                  display: flex;
                  align-items: center;
                  ion-img {
                    width: 100%;
                    max-width: 25px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//ion-toggle
ion-toggle {
  --background: #dadada;
  --background-checked: #4c78ff;
  --handle-background: #fff;
  --handle-background-checked: #fff;
  height: 20px;
  --handle-height: 18px;
  --handle-width: 18px;
  padding: 0px;
}

//shippingMethod
.methodBox {
  margin-bottom: 20px;
  .shippingMethod-heading {
    margin-bottom: 15px;
    h3 {
      color: #000000;
      font-family: "gothammedium";
      font-size: 16px;
      margin: 0px;
    }
  }
  .shippingMethod-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    .shippingMethod-left {
      display: flex;
      align-items: center;
      ion-checkbox {
        margin-right: 10px;
        --background: #fff;
        --border-color: #4c78ff;
        --border-radius: 100%;
        --background-checked: #4c78ff;
        min-width: 18px;
      }
      .usps-img {
        max-width: 40px;
        margin-right: 10px;
        ion-img {
          width: 100%;
          min-width: 40px;
          width: 40px;
        }
      }
      .shipping-detail {
        h3 {
          color: #000000;
          font-size: 16px;
          margin: 0px 0px 5px;
          max-width: 500px;
          line-height: 16px;
          font-family: "gothambook";
          font-weight: 700;
        }
        p {
          color: #000000;
          font-size: 12px;
          margin: 0px;
          max-width: 500px;
          line-height: 16px;
          font-family: "gothammedium";
        }
      }
    }
    .shippingMethod-right {
      p {
        color: #000000;
        font-size: 16px;
        margin: 0px;
        max-width: 500px;
        line-height: 16px;
        font-family: "gothammedium";
      }
    }
  }
  .shippingMethod-localPickup {
    pointer-events: none;
    opacity: 0.3;
  }
}

//profile and setting form (Additional Health Information)
.profile-detail {
  h3 {
    color: #000000;
    font-family: "gothammedium";
    margin: 0px 0px 5px;
    font-size: 15px;
    span {
      color: #4c78ff;
      font-weight: 600;
      margin-left: 15px;
    }
  }
  .profile-detail-pera {
    color: #000000;
    font-size: 12px;
    margin: 0px 0px 3px;
    line-height: 16px;
    font-weight: 400;
    font-family: "gothammedium";
  }

  //Health Information
  .health-information-group {
    margin-bottom: 15px;
    h3 {
      margin-bottom: 10px;
    }
    .allergy-list {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      a {
        background-color: #4c78ff1a;
        font-size: 14px;
        line-height: normal;
        font-weight: 500;
        font-family: "gothammedium";
        padding: 8px 10px;
        border-radius: 6px;
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        ion-icon {
          font-size: 22px;
          margin-left: 5px;
        }
      }
    }

    .form-group {
      margin-bottom: 15px;
      &.search-drop {
        .search-drop-list {
          top: 40px;
        }
      }
    }
  }
}

// product-item CSS
.product-item {
  width: 20%;
  padding: 10px;
  .product-img {
    background-color: #4c78ff1a;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 8px;
    max-width: 120px;
    ion-img {
      width: 100%;
      max-width: 100px;
    }
  }
  .product-detail {
    text-align: center;
    margin-bottom: 15px;
    h3 {
      color: #141516;
      font-size: 16px;
      font-family: "gothammedium";
      margin: 0px 0px 5px 0px;
      a {
        color: #141516;
        &:hover {
          color: #4c78ff;
        }
      }
    }
    p {
      color: #000000;
      font-size: 13px;
      margin: 0;
      line-height: 14px;
      font-weight: 400;
      font-family: "gothammedium";
    }
  }
  .addcart-btn {
    text-align: center;
    .theme-btn {
      //font-size: 12px;
      --padding-end: 10px;
      --padding-start: 10px;
    }
  }
}

.theme-modal {
  .modal-wrapper {
    .ion-page {
      justify-content: initial;
      background-color: #ffffff;
      .modal-header {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .modal-heading {
          width: calc(100% - 50px);
          h2 {
            color: #000000;
            font-family: "gothammedium";
            font-size: 20px;
            margin: 0px;
          }
        }
        .modal-closer {
          width: 50px;
          max-width: 50px;
          ion-button {
            border: none;
            outline: none;
            --border-radius: 100%;
            cursor: pointer;
            --box-shadow: none;
            --padding-bottom: 0px;
            --padding-end: 0px;
            --padding-start: 0px;
            --padding-top: 0px;
            width: 30px;
            min-width: 30px;
            height: 30px;
            --background: transparent;
            --background-activated: #1a1a1a;
            ion-icon {
              font-size: 25px;
              color: #4268dc;
            }
          }
        }
      }
      .modal-body {
        padding: 15px;
      }
      .modal-footer {
        padding: 0px 15px 15px 15px;
        text-align: center;
        .theme-btn {
          font-size: 16px;
          min-width: 120px;
        }
      }
    }
  }
}

.delete-medicine-modal {
  .modal-wrapper {
    max-height: 126px;
    max-width: 90%;
    border-radius: 6px;
  }
  .modal-body {
      .delete-medicine-content {
        margin-bottom: 40px;
        text-align: center;
        h3 {
          color: #000000;
          font-family: "gothammedium";
          font-size: 20px;
          margin: 0px;
        }
      }
      .delete-medicine-btns {
        text-align: center;
        .theme-btn {
          margin: 0px 10px;
        }
      }
  }
}

.quick-reminder-modal {
  .modal-wrapper {
    max-height: 250px;
    max-width: 90%;
    border-radius: 6px;
    .ion-page {
      padding: 30px 20px;
    }
  }
  .modal-body {
    .delete-medicine-content {
      margin-bottom: 40px;
      text-align: center;
      h3 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 20px;
        margin: 0px;
      }
    }
    .delete-medicine-btns {
      text-align: center;
      .theme-btn {
        margin: 0px 10px;
      }
    }
  }
}

//quick-reminder-modal modal
.quick-reminder-modal {
  .modal-wrapper {
    max-height: 250px;
    max-width: 90%;
    border-radius: 6px;
    .ion-page {
      padding: 30px 20px;
    }
  }
  .modal-body {
    .delete-medicine-content {
      margin-bottom: 40px;
      text-align: center;
      h3 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 20px;
        margin: 0px;
      }
    }
    .delete-medicine-btns {
      text-align: center;
      .theme-btn {
        margin: 0px 10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .quick-reminder-modal {
    .modal-wrapper {
      max-height: 350px;
      .ion-page {
        padding: 30px 15px;
      }
    }
    .modal-body {
      .delete-medicine-content {
        h3 {
          font-size: 16px;
        }
      }
      .delete-medicine-btns {
        text-align: center;
        .theme-btn {
          margin: 0px 5px 10px;
        }
      }
    }
  }
}
//price-match modal
.price-match-modal {

  &.thank-you {
    --height: 250px
  }

  @media (max-width: 768px) {
    --width: 85%;
  }

  .modal-wrapper {
    border-radius: 10px;

    @media (max-width: 768px) {
      border-radius: 0;
    }

    .ion-page .modal-body {
      padding: 40px 15px;

      h3 {
        font-family: "gothammedium";
        color: #000000;
        font-size: 30px;
        line-height: 39px;
        margin: 0 auto 10px;
        text-align: center;
      }

      p {
        font-family: "gothambook";
        max-width: 437px;
        font-size: 15px;
        line-height: 20px;
        color: #141516;
        margin: 0 auto 40px;
        text-align: center;
      }

      .form-group {
        max-width: 345px;
        margin: 0 auto 30px;
      }

      .theme-btn {
        width: 100%;
        --padding-top: 15px;
        --padding-bottom: 15px;
      }

      .close-icon {
        position: absolute;
        right: 22px;
        top: 31px;
        cursor: pointer;
      }
    }
  }
}

//accountSummary-page modal start

.accountSummary-delete-modal {
  .modal-body {
    // .delete-medicine-content {
    //   margin-top: 30px;
    // }
    .delete-medicine-btns {
      text-align: center;
      .theme-btn {
        margin: 0px 5px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .accountSummary-delete-modal {
    .modal-body {
      .delete-medicine-content {
        margin-top: 0px;
        margin-bottom: 15px;
      }
      .delete-medicine-btns {
        text-align: center;
        .theme-btn {
          margin: 5px 5px;
          font-size: 14px;
        }
      }
    }
  }
}

//accountSummary-page modal end

//delete-medicine modal
.delete-edit-modal {
  .modal-wrapper {
    max-height: 250px;
    max-width: 90%;
    border-radius: 6px;
  }
  .modal-body {
    .delete-medicine-content {
      margin-bottom: 40px;
      text-align: center;
      h3 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 24px;
        margin: 0px;
      }
    }
    .delete-medicine-btns {
      text-align: right;
      .theme-btn {
      }
    }
    .edit-medicine-content {
      margin-bottom: 40px;
      h3 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 24px;
        margin: 0px 0px 15px;
      }
      p {
        color: #000000;
        font-size: 14px;
        margin: 0px 0px 8px;
        line-height: 16px;
        font-weight: 400;
        font-family: "gothammedium";
      }
    }
    .edit-medicine-btns {
      text-align: right;
      .theme-btn {
      }
    }
  }
}

//responsive scss
@media only screen and (max-width: 767px) {
  //card start
  .theme-card {
    .card-header {
      padding: 25px 10px 0px;
    }
    .card-body {
      padding: 25px 10px;
    }
  }
  //card end

  //forgot flow start
  .forgotflow {
    .login-page-inner {
      margin: 30px auto;
      .theme-card {
        .card-body {
          .parentform {
            .signin-left-img {
              display: none;
            }
          }
        }
      }
    }
  }
  //forgot flow end

  //methodBox start
  .methodBox {
    margin-bottom: 20px;
    .shippingMethod-item {
      flex-flow: column;
      .shippingMethod-right {
        margin-left: 30px;
        margin-top: 5px;
      }
    }
  }
  //methodBox end
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  //methodBox start
  .methodBox {
    margin-bottom: 20px;
    .shippingMethod-item {
      flex-flow: column;
      .shippingMethod-right {
        margin-left: 36px;
      }
    }
  }
  //methodBox end
}

//commonCalendar
.commonCalendar {
  .react-calendar {
    box-shadow: 0px 3px 6px #00000029;
    padding-bottom: 10px;
    background-color: #fff;
    // react-calendar__navigation
    .react-calendar__navigation {
      padding: 0px 0px;
      width: 100%;
      justify-content: center;
      .react-calendar__navigation__arrow {
        color: #000;
        margin: 0px;
        font-size: 30px;
        background-color: #fff;
        outline: none;
        padding: 8px;
      }
      .react-calendar__navigation__label {
        background-color: #fff;
        &:focus {
          outline: none;
        }
        .react-calendar__navigation__label__labelText {
          color: #000;
          margin: 0px;
          font-size: 14px;
          font-family: "gothammedium";
        }
      }
    }
    //react-calendar__viewContainer
    .react-calendar__viewContainer {
      .react-calendar__month-view {
        //react-calendar__month-view__weekdays
        .react-calendar__month-view__weekdays {
          background-color: #4c78ff2e;
          .react-calendar__month-view__weekdays__weekday {
            text-align: center;
            padding: 10px 0px;
            abbr {
              font-size: 12px;
              font-family: "gothammedium";
              white-space: nowrap;
              color: #000;
              text-transform: uppercase;
              text-decoration: none;
            }
          }
        }
        //react-calendar__month-view__days
        .react-calendar__month-view__days {
          .react-calendar__tile {
            background-color: #fff;
            padding: 5px 0px;
            outline: none;
            text-align: center;
            width: 12%;
            abbr {
              font-size: 12px;
              font-family: "gothammedium";
              white-space: nowrap;
              color: #000;
              text-transform: uppercase;
              text-decoration: none;
            }
            &.react-calendar__month-view__days__day--neighboringMonth {
              abbr {
                color: #000;
                opacity: 0.4;
              }
            }
            &.react-calendar__tile--active {
              abbr {
                color: #fff;
                background-color: #4c78ff;
                border-radius: 100%;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px auto;
              }
            }
          }
        }
      }
      //react-calendar__year-view
      .react-calendar__year-view {
        .react-calendar__year-view__months {
          .react-calendar__tile {
            background-color: #fff;
            padding: 5px 0px;
            outline: none;
            text-align: center;
            color: #000;
          }
        }
      }
      //react-calendar__decade-view
      .react-calendar__decade-view {
        .react-calendar__decade-view__years {
          .react-calendar__tile {
            background-color: #fff;
            padding: 5px 0px;
            outline: none;
            text-align: center;
            color: #000;
          }
        }
      }
      //react-calendar__century-view
      .react-calendar__century-view {
        .react-calendar__century-view__decades {
          .react-calendar__tile {
            background-color: #fff;
            padding: 5px 0px;
            outline: none;
            text-align: center;
            color: #000;
          }
        }
      }
    }
  }
}

//cart modal start
.cart-modal {
  justify-content: flex-end;
  align-items: flex-start;
  .modal-wrapper {
    max-width: 400px;
    max-height: 400px;
    margin-top: 70px;
    margin-right: 20px;
    .modal-body {
      .cartdrop-card {
        .cart-closer {
          text-align: right;
          .cart-closer-btn {
            --background: none;
            color: #4c78ff;
            font-size: 24px;
            --padding-bottom: 0px;
            --padding-end: 0px;
            --padding-start: 0px;
            --padding-top: 0px;
          }
        }
        .cartdrop-mid {
          .order-detail-box-list {
            border-bottom: 1px solid #6d6d6d33;
            margin-bottom: 15px;
            max-height: 240px;
            overflow: auto;
            //order-detail-box
            .order-detail-box {
              margin-bottom: 5px;
              table {
                width: 100%;
                tr {
                  td {
                    padding: 5px;
                    vertical-align: top;
                    &:nth-last-child(1) {
                      width: 70px;
                    }
                    .text-blue {
                      color: #4c78ff !important;
                    }
                    .order-item-detail {
                      h3 {
                        color: #141516;
                        font-size: 16px;
                        font-family: "gothammedium";
                        margin: 0px 0px 8px;
                      }
                      p {
                        color: #000000;
                        font-size: 12px;
                        margin: 0px 0px 8px;
                        line-height: 16px;
                        font-weight: 400;
                        font-family: "gothambook";
                        &:nth-last-child(1) {
                          margin: 0px;
                        }
                        &.small-text {
                          font-size: 10px;
                        }
                      }
                    }
                    .order-item-price {
                      text-align: right;
                      p {
                        color: #141516;
                        font-size: 16px;
                        font-family: "gothammedium";
                        margin: 0px;
                      }
                    }
                  }
                }
              }

              .order-detail-btn {
                .theme-btn {
                  width: 100%;
                  --padding-bottom: 20px;
                  --padding-top: 20px;
                }
              }
            }
          }

          //subtotal
          .subtotal {
            display: flex;
            align-items: center;
            .subtotal-left {
              width: calc(100% - 100px);
              text-align: right;
              h3 {
                color: #141516;
                font-size: 16px;
                font-family: "gothammedium";
                margin: 0px;
              }
            }
            .subtotal-right {
              padding: 0px 5px;
              width: 100px;
              text-align: right;
              p {
                color: #141516;
                font-size: 16px;
                font-family: "gothammedium";
                margin: 0px;
              }
            }
          }
        }
        .cartdrop-bottom {
          display: flex;
          justify-content: center;
          padding-top: 15px;
          .theme-btn {
            margin: 0px 2px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .cart-modal {
    justify-content: center;
    align-items: flex-start;
    .modal-wrapper {
      margin-top: 0px;
      max-width: 100%;
      max-height: 100%;
      margin-right: 0px;
      .modal-body {
        .cartdrop-card {
          .cartdrop-mid {
            .order-detail-box-list {
              max-height: calc(100vh - 170px);
              //order-detail-box
              .order-detail-box {
                table {
                  tr {
                    td {
                      .order-item-detail {
                        h3 {
                          font-size: 14px;
                        }
                      }
                      .order-item-price {
                        p {
                          font-size: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }

            //subtotal
            .subtotal {
              .subtotal-left {
                h3 {
                  font-size: 14px;
                }
              }
              .subtotal-right {
                p {
                  font-size: 14px;
                }
              }
            }
          }
          .cartdrop-bottom {
            .theme-btn {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .cart-modal {
    justify-content: center;
    align-items: center;
    .modal-wrapper {
      margin-top: 0px;
      margin-right: 0px;
    }
  }
}

//cart modal end

//calculator-modal
.calculator-modal {
  .modal-wrapper {
    max-height: 360px;
    max-width: 90%;
    border-radius: 6px;
  }
  .modal-body {
    .modal-closer {
      text-align: right;
      a {
        color: #4268dc;
        display: block;
        ion-icon {
          font-size: 30px;
        }
        &:hover {
          color: #000;
        }
      }
    }
    .form-group {
      margin-bottom: 0px;
      ion-label {
        color: #000;
      }
      textarea {
        min-height: 100px;
      }
    }
    .calculator-modal-btns {
      text-align: center;
      margin-top: 20px;
      .theme-btn {
        margin: 5px 10px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .calculator-modal {
    .modal-wrapper {
      max-height: 400px;
    }
  }
}

//calculator-modal end

//results-modal
.results-modal {
  .modal-wrapper {
    max-height: 250px;
    max-width: 450px;
    border-radius: 6px;
    width: 90%;
  }
  .modal-body {
    .modal-closer {
      text-align: right;
      a {
        color: #4268dc;
        display: block;
        ion-icon {
          font-size: 30px;
        }
        &:hover {
          color: #000;
        }
      }
    }
    .results-modal-content {
      margin-bottom: 20px;
      text-align: center;
      h3 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 24px;
        margin: 0px 0px 15px;
        line-height: 30px;
      }
      p {
        color: #000000;
        font-family: "gothammedium";
        font-size: 18px;
        line-height: 24px;
        margin: 0px 0px 15px;
      }
    }
    .form-group {
      margin-bottom: 0px;
      ion-label {
        color: #000;
      }
      textarea {
        min-height: 100px;
      }
    }
    .calculator-modal-btns {
      text-align: center;
      margin-top: 20px;
      .theme-btn {
        margin: 5px 10px;
        width: 100%;
        max-width: 160px;
        &.okay-btn {
          max-width: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .results-modal {
    .modal-wrapper {
      max-height: 330px;
    }
  }
}
//results-modal end

#root {
  zoom: 110%;
}

.custom-height-ion {
  --max-height: 475px;
}
