.confirmationDone-page {
  padding-top: 50px;
  .confirmation-image {
    // margin-top: -35px;
    // margin-right: -35px;
    ion-img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .confirmationDone-page {
    padding: 30px 0px;
    .confirmation-image {
      display: none;
    }
    .theme-card {
      .card-body {
        .parentform {
          .parentform-heading {
            h2 {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .confirmationDone-page {
    .confirmation-image {
      display: none;
    }
  }
}
