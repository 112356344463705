.login-page {
  .login-page-inner {
    max-width: 800px;
    margin: 50px auto;
    .theme-card {
      .card-body {
        .parentform {
          .signin-left-img {
            margin-bottom: -35px;
            margin-left: -35px;
            ion-img {
              width: 100%;
            }
          }
          .parentform-right {
            .parentform-right-head {
              text-align: center;
              h2 {
                color: #000000;
                font-family: "gothammedium";
                font-size: 25px;
                margin: 0px 0px 15px;
              }
            }
            .parentform-button {
              .button-blue-outline {
                span {
                  display: flex;
                  align-items: center;
                  ion-img {
                    width: 100%;
                    max-width: 25px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .login-page {
    .login-page-inner {
      margin: 30px auto 30px;
      .theme-card {
        .card-body {
          padding: 25px 10px;
          .parentform {
            .signin-left-img {
              display: none;
            }
          }
        }
      }
    }
  }
}
