.sidebar-content {
  position: relative;
  margin-top: 50px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #fff;
    width: 4px;
    height: 100%;
    border-radius: 25px;
  }
  ion-list {
    background-color: transparent;
    padding-top: 0px;
    padding-bottom: 0px;
    ion-item {
      --background: transparent;
      --border-color: transparent;
      --padding-start: 0px;
      --padding-end: 0px;
      --padding-top: 0px;
      --padding-bottom: 0px;
      --min-height: auto;
      margin-top: -3px;
      a {
        color: #6d6d6d;
        font-size: 14px;
        padding: 14px 10px 14px 25px;
        font-family: "gothammedium";
        cursor: initial;
        &.active {
          position: relative;
          color: #4c78ff;
          &:after {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: #4c78ff;
            width: 4px;
            height: 100%;
            border-radius: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-content {
    display: none;
  }
}
