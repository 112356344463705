.accountSummary-page {
  .accountSummary-page-inner {
    max-width: 800px;
    margin: 0px auto;
    .accountSummary-cardparent {
      margin-bottom: 50px;
      .accountSummary-cardparent-heading {
        h3 {
          color: #141516;
          font-size: 16px;
          font-family: "gothammedium";
          margin-bottom: 15px;
        }
      }

      //active-orders-scroll
      .active-orders-scroll {
        max-height: 400px;
        overflow: auto;
      }
      .theme-card {
        margin-bottom: 15px;
        &.green-label {
          position: relative;
          overflow: hidden;
          &::before {
            content: "";
            position: absolute;
            top: -50px;
            right: -50px;
            width: 100px;
            height: 100px;
            z-index: 1;
            background-color: #76f9d2;
            transform: rotate(45deg);
          }
        }
        .card-header {
          padding: 25px;
          position: relative;
          .carddrop-icon {
            position: absolute;
            top: 25px;
            right: 25px;
            a {
              color: #141516;
              font-size: 22px;
              ion-icon {
              }
            }
          }
          .order-top {
            .order-number {
              h3 {
                color: #141516;
                font-size: 16px;
                font-family: "gothammedium";
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                margin-top: 0px;
                span {
                  font-family: "gothambook";
                  display: flex;
                  align-items: center;
                  margin-left: 20px;
                  font-size: 14px;
                  ion-icon {
                    //color: #efb55d;
                    font-size: 18px;
                    margin-right: 5px;
                  }
                }
              }
            }
            .tracking-number {
              p {
                color: #000000;
                font-size: 12px;
                margin: 0px 0px 3px;
                line-height: 16px;
                font-weight: 400;
                font-family: "gothammedium";
                span {
                  color: #4c78ff;
                }
              }
            }
            .order-date {
              p {
                color: #000000;
                font-size: 12px;
                margin: 0px 0px 3px;
                line-height: 16px;
                font-weight: 400;
                font-family: "gothammedium";
              }
            }
          }
          //cancel-order-btn
          .cancel-order-btn {
            position: absolute;
            right: 15px;
            top: 50px;
            z-index: 1;
          }
        }
        .card-body {
          //padding-top: 0px;
          .order-detail-box {
            border-radius: 5px;
            margin-bottom: 15px;
            &.bg-grey {
              background-color: #f7f8fb;
            }
            table {
              width: 100%;
              tr {
                td {
                  padding: 10px;
                  &:nth-last-child(1) {
                    width: 100px;
                  }
                  .text-blue {
                    color: #4c78ff !important;
                  }
                  .order-item-detail {
                    h3 {
                      color: #141516;
                      font-size: 16px;
                      font-family: "gothammedium";
                      margin: 0px 0px 8px;
                    }
                    p {
                      color: #000000;
                      font-size: 12px;
                      margin: 0px 0px 8px;
                      line-height: 16px;
                      font-weight: 400;
                      font-family: "gothammedium";
                      &:nth-last-child(1) {
                        margin: 0px;
                      }
                      &.small-text {
                        font-size: 10px;
                      }
                    }
                  }
                  .order-item-price {
                    text-align: right;
                    p {
                      color: #141516;
                      font-size: 16px;
                      font-family: "gothammedium";
                      margin: 0px;
                    }
                  }
                }
              }
            }

            .order-detail-btn {
              .theme-btn {
                width: 100%;
                --padding-bottom: 20px;
                --padding-top: 20px;
              }
            }
          }
        }
      }
      .accountSummary-cardparent-bottom {
        margin-top: 10px;
      }
    }
  }
  //empty-text
  .empty-text {
    text-align: center;
    width: 100%;
  }
}

//responsive scss
@media only screen and (max-width: 767px) {
  .accountSummary-page {
    .accountSummary-page-inner {
      .accountSummary-cardparent {
        .accountSummary-cardparent-heading {
          h3 {
          }
        }
        .theme-card {
          margin-bottom: 15px;
          &.green-label {
            position: relative;
            overflow: hidden;
            &::before {
              content: "";
              position: absolute;
              top: -50px;
              right: -50px;
              width: 100px;
              height: 100px;
              z-index: 1;
              background-color: #76f9d2;
              transform: rotate(45deg);
            }
          }
          .card-header {
            padding: 15px;
            .carddrop-icon {
              top: 15px;
              right: 15px;
            }
            .order-top {
              .order-number {
                h3 {
                  flex-flow: column;
                  align-items: flex-start;
                  span {
                    margin-left: 0px;
                    margin-top: 3px;
                  }
                }
              }
            }
            //cancel-order-btn
            .cancel-order-btn {
              position: static;
            }
          }
          .card-body {
            padding-top: 0px;
            .order-detail-box {
              border-radius: 5px;
              margin-bottom: 15px;
              &.bg-grey {
                background-color: #f7f8fb;
              }
              table {
                width: 100%;
                tr {
                  td {
                    padding: 10px;
                    &:nth-last-child(1) {
                      width: 100px;
                    }
                    .text-blue {
                      color: #4c78ff !important;
                    }
                    .order-item-detail {
                      h3 {
                        color: #141516;
                        font-size: 16px;
                        font-family: "gothammedium";
                        margin: 0px 0px 8px;
                      }
                      p {
                        color: #000000;
                        font-size: 12px;
                        margin: 0px 0px 8px;
                        line-height: 16px;
                        font-weight: 400;
                        font-family: "gothammedium";
                        &:nth-last-child(1) {
                          margin: 0px;
                        }
                        &.small-text {
                          font-size: 10px;
                        }
                      }
                    }
                    .order-item-price {
                      text-align: right;
                      p {
                        color: #141516;
                        font-size: 16px;
                        font-family: "gothammedium";
                        margin: 0px;
                      }
                    }
                  }
                }
              }

              .order-detail-btn {
                .theme-btn {
                  width: 100%;
                  --padding-bottom: 20px;
                  --padding-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
