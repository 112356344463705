$desktop: 1199.98px;
$medium: 991.98px;
$small: 767.98px;
$x-small: 575.98px;

@mixin desktop {
  @media (max-width: $desktop) {
    @content;
  }
}
@mixin medium {
  @media (max-width: $medium) {
    @content;
  }
}
@mixin small {
  @media (max-width: $small) {
    @content;
  }
}
@mixin x-small {
  @media (max-width: $x-small) {
    @content;
  }
}

.switchQwarkPage {
  padding: 90px 0;

  @include desktop {
    padding: 50px 0;
  }

  @include small {
    padding: 20px 0;
  }

  .theme-card {
    padding: 80px;

    @include desktop() {
      padding: 40px;
    }

    @include small {
      padding: 15px;
    }

    .card-body {
      padding: 0;
    }
  }

  &__content {
    max-width: 450px;
    @include desktop() {
      max-width: 400px;
    }
    @include medium {
      max-width: 100%;
    }
    &Title {
      font-family: "gothammedium";
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 30px;
      margin-top: 0;
      @include desktop() {
        font-size: 24px;
        margin-bottom: 20px;
      }
      @include x-small() {
        font-size: 22px;
        margin-bottom: 10px;
      }
    }
    &Desc {
      color: #000000;
      font-family: "gothambook";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 35px;
      @include desktop() {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
      }
      @include x-small() {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }
    &Icon {
      background: #4c78ff;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 20px;
    }
    &List {
      list-style-type: none;
      margin: 0;
      padding: 0;
      max-width: 410px;

      @include medium {
        max-width: 100%;
      }

      &Item {
        color: #000000;
        font-family: "gothambook";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        position: relative;

        @include x-small() {
          font-size: 14px;
          margin-bottom: 15px;
        }
      }
    }
  }

  &__form {
    max-width: 345px;
    margin-left: auto;

    @include medium {
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @include x-small {
      max-width: 100%;
    }

    &Row {
      margin: 0 -8px;
      display: flex;
      flex-wrap: wrap;
    }

    &Col6 {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 8px;
    }

    .form-group {
      margin-bottom: 25px;
      &--dob,
      &--search {
        .icon-input a {
          right: auto;
          left: 12px;
          z-index: 1055;
        }
        .native-input {
          padding-left: 38px;
        }
      }

      &--search {
        .native-input {
          &::-webkit-input-placeholder {
            color: #6d6d6d;
          }
          &::-moz-placeholder {
            color: #6d6d6d;
          }
          &:-ms-input-placeholder {
            color: #6d6d6d;
          }
          &:-moz-placeholder {
            color: #6d6d6d;
          }
        }
      }
      
      .dob-input {
        input::-webkit-inner-spin-button,
        input::-webkit-calendar-picker-indicator {
          display: none;
          -webkit-appearance: none;
        }
      }
      
      .calendar-icon {
        pointer-events: painted;
      }
    }
  }

  &__vectorHolder {
    order: 1;

    @include medium {
      order: 0;
      margin-bottom: 30px;
    }

    @include small() {
      margin-top: 30px;
    }
  }

  &__vector {
    position: relative;
    text-align: center;
  }
}
