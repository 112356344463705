//page_2_99
.page_2_99 {
  padding: 50px 0px;
  .meds-box {
    max-width: 450px;
    margin: 0px auto;
    .meds-box-head {
      text-align: center;
      margin-bottom: 40px;
      h2 {
        color: #000000;
        font-family: "gothammedium";
        margin: 0px;
      }
    }
    .meds-box-mid {
      margin-bottom: 40px;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
      .meds-heading {
        margin-bottom: 15px;
        h4 {
          color: #000000;
          font-size: 14px;
          margin: 0;
          line-height: 18px;
          font-weight: 400;
          font-family: "gothammedium";
        }
      }
      .meds-list {
        //items
        .meds-item {
          margin-bottom: 10px;
          ion-grid {
            padding: 0px;
            ion-row {
              align-items: center;
              padding: 0px;
            }
          }
          .meds-imgname {
            display: flex;
            align-items: center;
            .meds-img {
              max-width: 70px;
              min-width: 70px;
              margin-right: 10px;
              ion-img {
                width: 100%;
              }
            }
            .meds-name {
              h3 {
                color: #000000;
                font-size: 12px;
                margin: 0;
                line-height: 16px;
                font-weight: 400;
                font-family: "gothammedium";
                word-break: break-all;
              }
            }
          }
          .meds-price-btn {
            display: flex;
            align-items: center;
            .meds-price {
              margin-right: 10px;
              p {
                color: #000000;
                font-size: 12px;
                margin: 0;
                line-height: 16px;
                font-weight: 400;
                font-family: "gothammedium";
              }
            }
            .meds-btn {
              .theme-btn {
                --padding-end: 10px;
                --padding-start: 10px;
                font-size: 12px;
              }
            }
          }
          .meds-brif {
            p {
              color: #000000;
              font-size: 12px;
              margin: 0;
              line-height: 16px;
              font-weight: 400;
              font-family: "gothammedium";
            }
          }
        }
      }
    }
  }
}
