.google-sign-btn {
  --padding-end: 0px !important;
  --padding-start: 0px !important;
  .abcRioButton {
    width: 100% !important;
    position: relative;
    z-index: 999;
    .abcRioButtonContentWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .abcRioButtonContents {
        width: 146px;
        span {
          position: relative;
          min-width: 120px;
            &:after {
              content: "Sign in with Google";
              position: absolute;
              left: 0;
              top: 0;
              z-index: 99999;
              height: 100%;
              background-color: #fff;
              font-family: "gothammedium";
              font-weight: 500;
              font-size: 13px;

          }
        }
      }
    }
  }
}
