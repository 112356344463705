.prescriber-page {
  padding-top: 50px;
  .theme-card {
    .card-body {
      .parentform-right {
        .form-group {
          .left-icon-input {
            a {
              ion-spinner {
                color: #4c78ff;
              }
            }
          }
          // search-drop css
          &.search-drop {
            position: relative;
            .search-drop-list {
              background-color: #fff;
              box-shadow: 0px 3px 25px #00000029;
              padding: 10px;
              border-radius: 4px;
              position: absolute;
              width: 100%;
              z-index: 3;
              left: 0;
              top: 60px;
              .phone-or-email {
                display: contents;
              }
              a {
                color: #4c78ff;
                font-size: 13px;
                line-height: 16px;
                font-family: "gothammedium";
                display: block;
                margin-bottom: 6px;
                &:nth-last-child(1) {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .prescriber-page {
    padding: 30px 0px;
  }
}
