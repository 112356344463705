.profile-page {
  padding-top: 50px;
  .form-group {
    .form-control {
      color: #000;
      .native-input[disabled] {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .profile-page {
    padding: 30px 0px;
  }
}

@media only screen and (max-width: 425px) {
  .gender-group {
    .radio-item {
      margin-bottom: 5px;
    }
    .gender-radio {
      border: 1px solid #4c78ff;
      border-radius: 50px;
    }
  }
}

.dob-input {
  input::-webkit-inner-spin-button,
  input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
.calendar-icon {
  pointer-events: painted;
}
