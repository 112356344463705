//savings-calculator-page
.savings-calculator-page {
  .savings-header {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 0px 0px;
    z-index: 999;
    ion-grid {
      ion-row {
        align-items: center;
      }
    }
    .logo {
      a {
        ion-img {
          max-width: 140px;
        }
      }
    }
    .header-btn {
      text-align: right;
      .theme-btn {
      }
    }
    .header-logo {
      display: flex;
    }
    .calculator-icon {
      width: 55px;
    }
    .logo-img {
      margin-right: 20px;
    }
  }

  .savings-calculator-inner {
    padding: 60px 0px;
    //savings-calculator-header
    .savings-calculator-header {
      text-align: center;
      margin-bottom: 40px;
      h1 {
        color: #141516;
        font-size: 22px;
        font-family: "gothammedium";
        margin: 0px 0px 8px 0px;
      }
      p {
        color: #000000;
        font-size: 14px;
        margin: 0;
        line-height: 16px;
        font-weight: 400;
        font-family: "gothambook";
      }
      .notification-text {
        color: #3880ff;
        font-weight: bold;
      }
    }

    //calculator-cards
    .calculator-cards {
      margin-bottom: 50px;
      //theme-card
      .theme-card {
        position: relative;
        margin-bottom: 20px;
        .card-closer {
          position: absolute;
          top: 15px;
          right: 15px;
          a {
            color: #4268dc;
            display: block;
            ion-icon {
              font-size: 30px;
            }
            &:hover {
              color: #000;
            }
          }
        }
        .card-body {
          ion-row {
            ion-col {
              &:nth-child(1) {
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  background-color: #eef2ff;
                  right: 0;
                  top: 0;
                  height: 100%;
                  width: 1px;
                }
              }
            }
          }
          //calculator-search
          .calculator-search {
            padding: 0px 25px;
            .form-group {
              ion-label {
                color: #000000;
                margin-bottom: 10px;
              }
              .icon-input {
              }
              .calculator-search-inputpera {
                text-align: left;
                padding: 10px 0px;
                p {
                  color: #4c78ff;
                  font-size: 11px;
                  margin: 0;
                  line-height: 16px;
                  font-weight: 400;
                  font-family: "gothammedium";
                }
              }
            }
          }

          //calculator-cost
          .calculator-cost {
            padding: 0px 25px;
            .form-group {
              ion-label {
                color: #000000;
              }
              .form-brif {
                color: #000000;
                font-size: 12px;
                margin: 0px 0px 20px;
                line-height: 16px;
                font-weight: 400;
                font-family: "gothambook";
              }
              .calculator-cost-box {
                background-color: #f7f8fb;
                border-radius: 4px;
                padding: 20px 25px 15px;
                ion-range {
                  --bar-height: 10px;
                  --bar-border-radius: 10px;
                  --height: 26px;
                  --knob-size: 30px;
                  --bar-background: #4c78ff33;
                  --bar-background-active: #4c78ff;
                  --knob-background: #4c78ff;
                  --pin-background: #4c78ff;
                  --pin-color: #fff;
                }
                //range-points
                .range-points {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  span {
                    color: #000000;
                    font-size: 14px;
                    margin: 0;
                    line-height: 16px;
                    font-weight: 400;
                    font-family: "gothambook";
                    display: block;
                    padding: 0px 10px;
                  }
                }
              }
            }
          }
        }
      }

      //medication-add
      .medication-add {
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 25px;
        text-align: center;
        a {
          color: #4c78ff;
          font-size: 16px;
          font-family: "gothammedium";
        }
      }

      //show-savings
      .show-savings {
        text-align: center;
        .theme-btn {
          margin-bottom: 15px;
          width: 100%;
          max-width: 250px;
          font-family: "gothambook";
        }
        p {
          font-size: 16px;
          margin: 0px;
          line-height: 20px;
          display: block;
          font-family: "gothammedium";
          color: #000;
          a {
            color: #4c78ff;
          }
        }
      }
    }

    //results-box
    .results-box {
      border: 2px solid #54f0c2;
      border-radius: 10px;
      padding: 25px;
      background-color: #fff;
      margin-bottom: 20px;
      .heading {
        text-align: center;
        margin-bottom: 20px;
        h3 {
          color: #141516;
          font-size: 22px;
          font-family: "gothammedium";
          margin: 0px;
        }
      }
      .results-mid {
        text-align: center;
        max-width: 700px;
        margin: 0px auto;
        p {
          color: #000000;
          font-size: 14px;
          margin: 0px 0px 15px;
          line-height: 16px;
          font-weight: 400;
          font-family: "gothambook";
        }
        .results-cost {
          .results-cost-point {
            margin-bottom: 15px;
            padding: 15px;
            border-radius: 6px;
            background-color: #f7f8fb;
            &.bg-light-green {
              background-color: #76f9d2;
            }
            p {
              margin: 0px;
              font-family: "gothammedium";
            }
          }
        }
      }
    }
  }
}

//media css
//media css
@media only screen and (max-width: 767px) {
  //savings-calculator-page
  .savings-calculator-page {
    .savings-calculator-inner {
      //calculator-cards
      .calculator-cards {
        //theme-card
        .theme-card {
          .card-body {
            ion-row {
              ion-col {
                &:nth-child(1) {
                  &::after {
                    display: none;
                  }
                }
              }
            }
            //calculator-search
            .calculator-search {
              padding: 0px 0px;
            }

            //calculator-cost
            .calculator-cost {
              padding: 0px 0px;
              .form-group {
                .calculator-cost-box {
                  padding: 20px 10px 15px;
                  //range-points
                  .range-points {
                    span {
                      font-size: 12px;
                      padding: 0px 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      //results-box
      .results-box {
        padding: 25px 15px;
      }
    }
  }
}
