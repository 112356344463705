.medicationAccordionMain {
  padding-top: 25px;

  .medicationAccordionHeadingMain {
    font-family: "gothambook";
    font-weight: 800;
    font-size: 17px;
    line-height: 16px;
    padding-bottom: 10px;
  }
  .medicationAccordion {
    display: flex;
    flex-direction: column;
    align-items: revert;
    .medicationAccordionHeading {
      font-style: normal;
      font-family: "gothambook";
      font-weight: 800;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
    }
  }
  .medicationAccordionParagrph {
    font-family: "gothambook";
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
  }
  .accordionHead {
    display: flex;
    justify-content: space-between;

    .accordionRightCol {
      align-items: center;
      display: flex;
      flex-direction: column;
      .rightArrow {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        font-family: "gothambook";
        font-weight: 500;
        font-size: 20px;
        color: black;
        padding-bottom: 10px;
      }
      .medAddToCart {
        width: 120px;
      }
    }
  }
}
.showMoreBtn {
  display: flex;
  justify-content: center;
}

.noMedication {
  font-family: "gothambook";
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
  color: red;
  justify-content: center;
  display: flex;
}
