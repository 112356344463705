.forgotflow {
  .login-page-inner {
    .parentform-right {
      .form-group {
        .inline-datefilds {
          display: flex;
          justify-content: space-between;
          .dateicon-input {
            width: 30%;
            margin-right: 8px;
            &:nth-last-child(1) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
