//ask-to-join
.ask-to-join {
  text-align: center;
  padding: 40px 0px;
  h3 {
    color: #141516;
    font-size: 22px;
    font-family: "gothammedium";
    margin: 0px 0px 25px;
  }
}
