//about-page
.about-page {
  //about-banner
  .about-banner {
    padding: 40px 0px;
    .about-banner-head {
      text-align: center;
      max-width: 500px;
      margin: 0px auto 40px;
      h2 {
        color: #141516;
        font-size: 22px;
        line-height: 30px;
        font-family: "gothammedium";
        margin: 0px 0px 15px 0px;
      }
    }
    //about-banner-img
    .about-banner-img {
      ion-img {
        width: 100%;
        max-width: 600px;
        margin: 0px auto;
      }
    }
  }

  //about-banner
  .about-story {
    padding: 40px 0px;
    .about-story-head {
      max-width: 480px;
      margin: 0 auto 40px;
      text-align: center;
      .head {
        font-size: 35px;
      }
      h2 {
        color: #141516;
        font-size: 22px;
        font-family: "gothammedium";
        margin: 0px 0px 15px 0px;
        text-align: center;
      }
      p {
        color: #000000;
        font-size: 15px;
        margin: 0;
        line-height: 16px;
        font-weight: 400;
        font-family: "gothammedium";
        text-align: center;
      }
    }
    //about-story-img
    .about-story-img {
      ion-img {
        width: 100%;
      }
    }
    //about-story-img
    .our-story-content {
      max-width: 80%;
      margin: 0 auto;
      p {
        font-size: 15px;
        font-family: 'gothambook';
      }
    }
  }

  //our-team
  .our-team {
    padding: 40px 0px;
    //our-team-head
    .our-team-head {
      margin-bottom: 20px;
      .our-team-heading {
        h2 {
          color: #141516;
          font-size: 22px;
          font-family: "gothammedium";
          margin: 0px;
        }
      }
      .condition-pagination {
        display: flex;
        justify-content: flex-end;
        .theme-icon-btn {
          --background-hover: #4c78ff;
          --background-hover-opacity: 1;
          &.active {
            --background: #4c78ff;
          }
        }
      }
    }
    //our-team-slider
    .our-team-slider {
      ion-slides {
        .swiper-wrapper {
          ion-slide {
            //padding: 0px 10px;

            .team-img {
              ion-img {
                width: 100%;
                margin-bottom: 20px;
              }
              .team-image {
                height: 430px;
                object-fit: cover
              }
              .team-img-info {
                p {
                  color: #000000;
                  font-size: 16px;
                  margin: 0;
                  line-height: 22px;
                  font-weight: 400;
                  font-family: "gothammedium";
                  text-align: left;
                }
                p.member-info {
                  color: #000000;
                  margin: 0;
                  line-height: 22px;
                  text-align: left;
                  font-size: 15px;
                  font-family: 'gothambook';
                }
              }
            }
          }
        }
      }
    }
  }

  //our-values
  .our-values {
    padding: 40px 0px;
    //our-values-head
    .our-values-head {
      margin-bottom: 40px;
      text-align: center;
      .our-values-heading {
        margin-bottom: 20px;
        h2 {
          color: #141516;
          font-size: 22px;
          font-family: "gothammedium";
          margin: 0px;
        }
      }
      .our-values-head-img {
        ion-img {
          width: 100%;
          max-width: 200px;
          margin: 0px auto;
        }
      }
    }
    //our-values-mid
    .our-values-mid {
      display: flex;
      flex-flow: column;
      align-items: center;
      .our-values-box {
        background-color: #eef2ff;
        margin-bottom: 20px;
        border-radius: 8px;
        max-width: 900px;
        padding: 15px;
        ion-grid {
          ion-row {
            align-items: center;
          }
        }
        .our-values-img {
          ion-img {
            width: 100%;
            max-width: 200px;
            margin: 0px auto;
          }
        }
        .our-values-detail {
          h3 {
            color: #141516;
            font-size: 18px;
            font-family: "gothammedium";
            margin: 0px;
          }
          p {
            color: #000000;
            line-height: 16px;
            font-weight: 400;
            font-size: 15px;
            font-family: 'gothambook';
          }
        }
      }
    }
  }

  //press
  .press {
    padding: 40px 0px;
    .press-head {
      margin-bottom: 30px;
      h2 {
        color: #141516;
        font-size: 22px;
        font-family: "gothammedium";
        margin: 0px;
      }
    }
    .press-body {
      //press-box
      .press-box {
        max-width: calc(100% - 30px);
        .press-img {
          margin-bottom: 20px;
          ion-img {
            width: 100%;
            height: 177px;
            object-fit: unset;
          }
        }
        .press-box-detail {
          h3 {
            color: #141516;
            font-size: 18px;
            font-family: "gothammedium";
            margin: 0px;
          }
          p {
            color: #000000;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: "gothammedium";
          }
        }
      }
    }
  }
}

//media css
@media only screen and (max-width: 767px) {
}
