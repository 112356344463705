.checkoutConditions-page {
  padding-top: 50px;
  .theme-card {
    margin-bottom: 20px;
  }
  .parentform {
    .form-group {
      ion-label {
        color: #141516;
      }
      .allergy-list {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        flex-wrap: wrap;
        a {
          background-color: #4c78ff1a;
          font-size: 14px;
          line-height: normal;
          font-weight: 500;
          font-family: "gothammedium";
          padding: 8px 10px;
          border-radius: 6px;
          margin-right: 5px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          ion-icon {
            font-size: 22px;
            margin-left: 5px;
          }
        }
      }

      .search-drop {
        .search-drop-list {
          top: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .checkoutConditions-page {
    padding: 30px 0px;
    ion-grid {
      ion-row {
        padding-left: 0px;
        padding-right: 0px;
        ion-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}
