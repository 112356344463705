.healthNetwork-page {
  padding: 0px 0px 50px;
  .profileAndSetting-page-inner {
    max-width: 800px;
    margin: 0px auto;
    .parent-box {
      .parent-box-heading {
        margin-bottom: 10px;
        h3 {
          color: #000000;
          font-family: "gothammedium";
          margin: 0px;
          font-size: 15px;
          display: flex;
          align-items: center;
          a {
            color: #fff;
            background-color: #4c78ff;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            color: #fff;
            margin-left: 10px;
            ion-icon {
              margin-right: -0.3px;
            }
          }
        }
      }
      .theme-card {
        margin-bottom: 15px;
        .card-body {
          .profile-detail {
            h3 {
              color: #000000;
              font-family: "gothammedium";
              margin: 0px 0px 5px;
              font-size: 15px;
            }
            .profile-detail-pera {
              color: #000000;
              font-size: 12px;
              margin: 0px 0px 3px;
              line-height: 16px;
              font-weight: 400;
              font-family: "gothammedium";
            }
          }
          .form-group {
            margin-bottom: 10px;
            p {
              color: #000000;
              font-size: 12px;
              margin: 0px 0px 3px;
              line-height: 16px;
              font-weight: 400;
              font-family: "gothammedium";
            }

            // search-drop css
            &.search-drop {
              position: relative;
              .search-drop-list {
                background-color: #fff;
                box-shadow: 0px 3px 25px #00000029;
                padding: 10px;
                border-radius: 4px;
                position: absolute;
                width: 100%;
                z-index: 3;
                left: 0;
                top: 60px;
                .phone-or-email {
                  display: contents;
                }
                a {
                  color: #4c78ff;
                  font-size: 13px;
                  line-height: 16px;
                  font-family: "gothammedium";
                  display: block;
                  margin-bottom: 6px;
                  &:nth-last-child(1) {
                    margin-bottom: 0px;
                  }
                }
              }
            }
          }
          .profile-edit-btn {
            //text-align: right;
            display: flex;
            flex-flow: column;
            align-items: flex-end;
          }
        }
      }
    }
  }

  .profile-detail-pera {
    color: #000000;
    font-size: 12px;
    margin: 0px 0px 3px;
    line-height: 16px;
    font-weight: 400;
    font-family: "gothammedium";
  }
}

//responsive scss
@media only screen and (max-width: 767px) {
  .healthNetwork-page {
    .profileAndSetting-page-inner {
      .parent-box {
        .theme-card {
          .card-body {
            .profile-edit-btn {
              flex-flow: initial;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.flex-items-center {
  display: flex;
  align-items: center;
}
