//landing-page
.landing-page {
  //landing-banner
  .landing-banner {
    padding: 40px 0px;
    background-color: #fff;
    ion-grid {
      ion-row {
        align-items: center;
      }
    }
    .landing-banner-detail {
      max-width: 400px;
      .landing-banner-head {
        margin-bottom: 40px;
        h2 {
          color: #141516;
          font-size: 22px;
          font-family: "gothammedium";
          margin: 0px 0px 15px 0px;
        }
        p {
          color: #000000;
          font-size: 14px;
          margin: 0;
          line-height: 16px;
          font-weight: 400;
          font-family: "gothammedium";
        }
      }
      .landing-banner-search {
        max-width: 235px;
        .search-drop {
          .icon-input {
            .form-control {
              background-color: #fff;
            }
          }
          .search-drop-list {
            top: 40px;
            .phone-or-email {
              display: contents;
            }
            p {
              color: #000000;
              font-size: 14px;
              margin: 0;
              line-height: 16px;
              font-weight: 400;
              font-family: "gothammedium";
            }
          }
          .landing-banner-inputpera {
            text-align: left;
            padding: 10px 0px;
            p {
              color: #4c78ff;
              font-size: 11px;
              margin: 0;
              line-height: 16px;
              font-weight: 400;
              font-family: "gothammedium";
            }
          }
        }
        .btn-switch {
          margin: 10px 0px;
        }
      }
    }
    //landing-banner-img
    .landing-banner-img {
      ion-img {
        width: 100%;
      }
    }
  }

  //shopby-condition
  .shopby-condition {
    background-color: #f1f3f899;
    padding: 40px 0px;
    //shopby-condition-head
    .shopby-condition-head {
      .shopby-condition-heading {
        h2 {
          color: #141516;
          font-size: 22px;
          font-family: "gothammedium";
          margin: 0px;
        }
      }
      .condition-pagination {
        display: flex;
        justify-content: flex-end;
        .theme-icon-btn {
          --background-hover: #4c78ff;
          --background-hover-opacity: 1;
          &.active {
            --background: #4c78ff;
          }
        }
      }
    }
    //shopby-condition-body
    .shopby-condition-body {
      .condition-dropdown {
        .condition-dropdown-btn {
          margin-bottom: 10px;
          a {
            background-color: #4c78ff33;
            color: #141516;
            display: flex;
            justify-content: space-between;
            padding: 14px 15px;
            border-radius: 10px;
            font-family: "gothammedium";
            font-size: 14px;
            span {
            }
            ion-icon {
              color: #141516;
              min-width: 15px;
              margin-left: 8px;
            }
            &.condition-droup-hide {
              ion-icon {
                transform: rotate(180deg);
              }
            }
          }
        }
        .condition-dropdown-list {
          a {
            background-color: #f7f8fb;
            color: #4c78ff;
            display: block;
            padding: 12px 15px 18px;
            border-radius: 10px;
            font-family: "gothammedium";
            margin-bottom: 10px;
            font-size: 14px;
            text-align: left;
            span {
              padding-bottom: 2px;
              border-bottom: 3px solid #4c78ff;
            }
          }
        }
      }
    }
  }

  //landing-howitworks
  .landing-howitworks {
    padding: 40px 0px;
    background-color: #fff;
    .howitworks-head {
      text-align: center;
      max-width: 800px;
      margin: 0px auto 50px;
      p {
        color: #000000;
        font-size: 14px;
        margin: 0;
        line-height: 16px;
        font-weight: 400;
        font-family: "gothammedium";
      }
      span {
        color: #000000;
        font-size: 14px;
        margin: 0;
        line-height: 16px;
        font-weight: 400;
        font-family: "gothammedium";
        display: block;
        margin-bottom: 20px;
      }
      h2 {
        color: #141516;
        font-size: 22px;
        font-family: "gothammedium";
        margin-bottom: 15px;
      }
    }
    .howitworks-body {
      //howitworks-box
      .howitworks-box {
        max-width: 250px;
        .howitworks-point {
          margin-bottom: 15px;
          span {
            background-color: #4c78ff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            min-width: 30px;
            color: #fff;
            border-radius: 100%;
            font-size: 14px;
            font-family: "gothammedium";
            margin: 0px auto;
          }
        }
        .howitworks-img {
          width: 200px;
          margin: 0 auto;
          margin-bottom: 20px;
          ion-img {
            width: 100%;
          }
        }
        .howitworks-box-detail {
          h3 {
            color: #141516;
            font-size: 18px;
            font-family: "gothammedium";
            margin: 0px;
            min-height: 45px;
            display: flex;
            align-items: center;
          }
          p {
            color: #000000;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            font-family: "gothammedium";
          }
        }
      }
    }
  }

  //landing-whyqwark
  .landing-whyqwark {
    padding: 40px 0px 60px;
    background-color: #f1f3f899;
    .whyqwark-head {
      text-align: center;
      margin-bottom: 50px;
      h2 {
        color: #141516;
        font-size: 22px;
        font-family: "gothammedium";
        margin: 0px;
      }
    }
    .whyqwark-mid-img {
      text-align: center;
      ion-img {
        max-width: 400px;
        width: 100%;
        margin: 0px auto 20px;
      }
      .whyqwark-midimg-content {
        margin: 0px auto 30px;
        max-width: 400px;
        h3 {
          color: #141516;
          font-size: 18px;
          line-height: 26px;
          font-family: "gothammedium";
          margin: 0px;
          text-align: center;
        }
      }
    }
    .whyqwark-body {
      .whyqwark-box {
        max-width: 250px;
        h3 {
          color: #141516;
          font-size: 18px;
          font-family: "gothammedium";
          margin: 0px;
        }
        p {
          color: #000000;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          font-family: "gothammedium";
        }
      }
    }

    .certificate {
      .certificate-img {
        ion-img {
          width: 100%;
          max-width: 110px;
          object-fit: contain;
          margin: 0px auto;
        }
      }
      .certificate-content {
        p {
          color: #000000;
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
          font-family: "gothambook";
          &:nth-last-child(1) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  //landing-taketime
  .landing-taketime {
    padding: 50px 0px;
    text-align: center;
    // background-size: cover;
    // background-position: center;
    // position: relative;
    background-color: #efeff1;
    &:after {
      content: "";
      position: absolute;
      background-color: #fff;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }
    .landing-taketime-inner {
      position: relative;
      z-index: 1;
      h3 {
        color: #141516;
        font-size: 22px;
        font-family: "gothammedium";
        margin: 0px;
      }
    }
  }

  //landing-faq
  .landing-faq {
    padding: 40px 0px;
    background-color: #fff;
  }
}

//media css
@media only screen and (max-width: 767px) {
  //landing-page
  .landing-page {
    //landing-banner
    .landing-banner {
      .landing-banner-detail {
        max-width: 100%;
        .landing-banner-search {
          max-width: 100%;
        }
      }
    }

    //shopby-condition
    .shopby-condition {
      //shopby-condition-head
      .shopby-condition-head {
        .condition-pagination {
          justify-content: flex-start;
        }
      }
    }

    //landing-howitworks
    .landing-howitworks {
      .howitworks-body {
        //howitworks-box
        .howitworks-box {
          max-width: 100%;
        }
      }
    }

    //landing-whyqwark
    .landing-whyqwark {
      .whyqwark-body {
        .whyqwark-box {
          max-width: 100%;
        }
      }
    }
  }
}
