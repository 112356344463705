.medicineDetail {
  padding-top: 50px;

  //top-back
  .top-back {
    .back-link {
      color: #4c78ff;
      font-size: 16px;
      margin: 0px;
      max-width: 400px;
      line-height: 16px;
      font-family: "gothammedium";
    }
  }

  //medicineInfo
  .medicineInfo {
    .medicine-img {
      background-color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 8px;
      max-width: 150px;
      ion-img {
        width: 100%;
        max-width: 100px;
      }
    }
    .parentform {
      .parentform-heading {
        h2 {
        }
        p {
          margin-bottom: 15px;
          font-family: "gothambold";
        }
        a {
          font-size: 16px;
          margin: 0px;
          max-width: 500px;
          line-height: 16px;
          font-family: "gothammedium";
          color: #4c78ff;
        }
      }
      ion-item {
        overflow: visible;
      }
      .parentform-left-content {
        div {
          display: flex;
          align-items: center;
          .info-icon {
            margin-left: 4px;
            display: flex;
            position: relative;
            .icon-tooltip {
              padding: 10px;
              border: 1px solid;
              border-radius: 10px;
              position: absolute;
              min-width: 200px;
              left: 22px;
              top: -104px;
              display: none;
              p {
                font-family: "gothambook";
                font-size: 12px;
              }
            }
            &:hover {
              .icon-tooltip {
                display: block;
              }
            }
            ion-icon {
              color: #8a8888;
              font-size: 18px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  .theme-card {
    min-height: 100%;
    .card-body {
      //parentform-right
      .parentform {
        .parentform-right {
          .medicine-count {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .medicine-countdetail {
              margin-right: 10px;
              h3 {
                color: #141516;
                font-size: 16px;
                margin: 0px 0px 10px 0px;
                max-width: 400px;
                line-height: 16px;
                font-family: "gothammedium";
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: flex-end;
              }
              p {
                color: #4c78ff;
                font-size: 16px;
                margin: 0px;
                max-width: 400px;
                line-height: 16px;
                font-family: "gothammedium";
              }
            }
            .medicine-countprice {
              width: 120px;
              text-align: right;
              p {
                color: #000000;
                font-family: "gothammedium";
                font-size: 24px;
                margin: 0px;
              }
            }
          }

          //medicine-item
          .medicine-item {
            .form-group {
              ion-label {
                color: #000000;
                margin-bottom: 10px;
              }
              .medicine-item-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                a {
                  background-color: #a8a8c233;
                  color: #8b8ba9;
                  display: block;
                  border-radius: 6px;
                  font-size: 12px;
                  font-family: "gothammedium";
                  padding: 10px;
                  text-align: center;
                  margin: 0 5px 5px;
                  min-width: 85px;
                  &.active {
                    background-color: #4c78ff1a;
                    color: #4c78ff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //buyQwark
  .buyQwark {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    .buyQwark-heading {
      padding: 5px 0px;
      p {
        color: #000000;
        font-size: 12px;
        margin: 0px;
        line-height: 14px;
        font-weight: 400;
        font-family: "gothammedium";
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }
    .buyQwark-list {
      .buyQwark-item {
        display: flex;
        align-items: center;
        p {
          color: #000000;
          font-size: 12px;
          margin: 0px;
          line-height: 14px;
          font-weight: 400;
          font-family: "gothammedium";
          display: flex;
          align-items: center;
          margin-right: 5px;
          span {
            display: block;
            background-color: #4c78ff;
            min-width: 20px;
            height: 20px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
  }

  //help-section
  .help-section {
    background-color: #fff;
    padding: 30px 0px;
    .help-detail {
      h2 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 18px;
        margin: 0px 0px 10px;
      }
      .help-content {
        color: #141516;
        font-size: 13px;
        margin: 0px 0px 10px;
        line-height: 16px;
        font-family: "gothammedium";
      }
      .help-info {
        display: flex;
        align-items: center;
        a {
          color: #4c78ff;
          font-size: 13px;
          margin: 0px;
          line-height: 16px;
          font-family: "gothammedium";
          margin-right: 10px;
          ion-icon {
          }
        }
        .theme-btn {
          font-size: 12px;
        }
      }
    }
    .qr-detail {
      text-align: right;
      .qr-img {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        ion-img {
          width: 100%;
          max-width: 87px;
        }
      }
      a {
        //color: #4c78ff;
        color: #000;
        font-size: 13px;
        margin: 0px;
        line-height: 16px;
        font-family: "gothammedium";
        cursor: initial;
      }
    }
  }

  //about-medicine
  .about-medicine {
    max-width: 650px;
    margin: 0px auto;
    padding: 50px 0px;
    .about-medicine-head {
      margin-bottom: 15px;
      h2 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 22px;
        margin: 0px 0px 20px;
        text-align: center;
      }
      p {
        color: #141516;
        font-size: 13px;
        margin: 0px;
        line-height: 16px;
        font-family: "gothammedium";
      }
    }
    .about-medicine-body {
      .accordion-about {
        .accordion-item {
          margin-bottom: 15px;
          .accordion-item-top {
            a {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 0px;
              h3 {
                color: #141516;
                font-size: 14px;
                margin: 0px;
                line-height: 16px;
                font-family: "gothammedium";
              }
              ion-icon {
                color: #8b8ba9;
                min-width: 15px;
              }
            }
          }
          .accordion-item-detail {
            &.open_class {
              display: none;
            }
            p {
              color: #141516;
              font-size: 12px;
              margin: 0px;
              line-height: 16px;
              font-family: "gothambook";
            }
          }
        }
      }
    }
  }

  //customers-ordered
  .customers-ordered {
    background-color: #fff;
    padding: 50px 0px;
    border-bottom: 1px solid #6d6d6d33;
    .customers-ordered-head {
      text-align: center;
      margin-bottom: 20px;
      h2 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 18px;
        margin: 0px;
      }
    }
    .customers-ordered-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap;
      .product-item {
        .product-detail {
          text-align: left;
        }
      }
    }
  }
}

//responsive scss
@media only screen and (max-width: 767px) {
  .medicineDetail {
    .theme-card {
      min-height: 100%;
      .card-body {
        //parentform-right
        .parentform {
          .parentform-right {
            .medicine-count {
              flex-flow: column;
              align-items: flex-start;
              .medicine-countdetail {
                margin-right: 0px;
                margin-bottom: 5px;
              }
              .medicine-countprice {
                text-align: left;
              }
            }
          }
        }
      }
    }

    //buyQwark
    .buyQwark {
      .buyQwark-list {
        .buyQwark-item {
          display: block;
          p {
            margin-right: 0px;
            margin-bottom: 5px;
          }
        }
      }
    }

    //help-section
    .help-section {
      .help-detail {
        .help-content {
          margin-bottom: 10px;
        }
        .help-info {
          align-items: flex-start;
          flex-flow: column;
          a {
            margin-bottom: 5px;
          }
        }
      }
      .qr-detail {
        text-align: left;
        .qr-img {
          justify-content: flex-start;
        }
      }
    }

    //customers-ordered
    .customers-ordered {
      .customers-ordered-body {
        .product-item {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .medicineDetail {
    .theme-card {
      min-height: 100%;
      .card-body {
        //parentform-right
        .parentform {
          .parentform-right {
            .medicine-count {
              flex-flow: column;
              align-items: flex-start;
              .medicine-countdetail {
                margin-right: 0px;
                margin-bottom: 5px;
              }
              .medicine-countprice {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
