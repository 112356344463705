//common-faq
.common-faq {
  //common-faq-heading
  .common-faq-heading {
    text-align: center;
    margin-bottom: 40px;
    h3 {
      color: #141516;
      font-size: 22px;
      font-family: "gothammedium";
      margin: 0px;
    }
  }
  .common-faq-inner {
    max-width: 750px;
    margin: 0 auto;
    //faq-accordion
    .faq-accordion {
      .faq-accordion-btn {
        a {
          color: #141516;
          display: flex;
          justify-content: space-between;
          padding: 10px 0 0;
          font-family: "gothammedium";
          font-size: 14px;
          span {
            border-radius: 100%;
            min-width: 25px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #4c78ff1a;
            text-transform: initial;
            margin-left: 10px;
            ion-icon {
              font-size: 18px;
              color: #4c78ff;
            }
          }
        }
      }
      .faq-accordion-content {
        margin-right: 25px;
        padding: 14px 0;
        display: none;
        p {
          color: #141516;
          font-family: "gothammedium";
          font-size: 12px;
          margin: 0px 0px 10px;
          line-height: 16px;
          &:nth-last-child(1) {
            margin: 0px;
          }
        }
        &.open_class{
          display: block;
        }
      }
    }
  }
}
