.medication-page {
  padding: 0px 0px 50px;
  .medication-page-inner {
    max-width: 800px;
    margin: 0px auto;
    .theme-card {
      .card-body {
        .medication-search {
          .medication-search-sidespace {
            max-width: 500px;
            margin: 0px auto;
            .medication-search-head {
              margin-bottom: 15px;
              text-align: center;
              ion-icon {
                color: #4c78ff;
                font-size: 35px;
                transform: rotate(90deg);
              }
              h2 {
                color: #141516;
                font-size: 16px;
                font-family: "gothammedium";
                margin: 0px 0px 15px 0px;
              }
              p {
                color: #000000;
                font-size: 12px;
                margin: 0;
                line-height: 16px;
                font-weight: 400;
                font-family: "gothammedium";
              }
            }
            .medication-search-input {
              max-width: 400px;
              margin: 0px auto;
              .form-group {
                margin-bottom: 0px;
                &.search-drop {
                  .search-drop-list {
                    top: 40px;
                    .phone-or-email {
                      display: contents;
                    }
                    p {
                      color: #000000;
                      font-size: 14px;
                      margin: 0;
                      line-height: 16px;
                      font-weight: 400;
                      font-family: "gothammedium";
                    }
                  }
                }
                .icon-input {
                  margin: 0px auto;
                }
              }
              .medication-search-inputpera {
                text-align: center;
                padding: 10px 0px;
                p {
                  color: #4c78ff;
                  font-size: 12px;
                  margin: 0;
                  line-height: 16px;
                  font-weight: 400;
                  font-family: "gothammedium";
                }
                a {
                  color: #4c78ff;
                  font-size: 12px;
                  margin: 0;
                  line-height: 16px;
                  font-weight: 400;
                  font-family: "gothammedium";
                }
              }
            }
          }
          //search-products
          .search-products {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .medication-page {
    .medication-page-inner {
      .theme-card {
        .card-body {
          .medication-search {
            //search-products
            .search-products {
              // product-item
              .product-item {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}
