.profileAndSetting-page {
  padding: 0px 0px 50px;
  .profileAndSetting-page-inner {
    max-width: 800px;
    margin: 0px auto;
  }
  .patient-profile {
    margin-bottom: 20px;
    .patient-profile-heading {
      margin-bottom: 15px;
      h3 {
        color: #141516;
        font-size: 17px;
        font-family: "gothammedium";
        margin: 0px;
      }
    }
    .patient-profile-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .greybox {
        background-color: #8b8ba9cc;
        border-radius: 5px;
        padding: 8px;
        width: 100%;
        margin-right: 5px;
      }
      .bg-light-green {
        background-color: #76f9d2;
      }
    }
    .patient-profile-status {
      text-align: right;
      p {
        color: #000000;
        font-size: 12px;
        margin: 0px;
        line-height: 14px;
        font-weight: 400;
        font-family: "gothammedium";
      }
    }
  }

  .profile-row-space {
    margin-bottom: 15px;
  }

  .profile-edit-btn {
    text-align: right;
  }

  //profile-edit-form
  .profile-edit-form {
    border-bottom: 1px solid #8b8ba980;
    padding-bottom: 15px;
  }

  //Visa card
  .grey-card {
    background-color: #f7f8fb;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    margin-bottom: 10px;
    .grey-card-body {
      .profile-detail-pera {
        &.text-blue {
          text-transform: uppercase;
          color: #4c78ff;
        }
      }
    }
    .grey-card-bottom {
      .profileedit-btn-inline {
        .theme-btn {
          font-size: 12px;
          height: auto;
        }
      }
    }
  }
}

//responsive scss
@media only screen and (max-width: 767px) {
  .profileAndSetting-page {
    .profile-edit-btn {
      text-align: left;
    }
  }
}
