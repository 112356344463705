.checkbox-disabled {
  opacity: 1;
}
.shoppingCart {

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .tooltip {
    color: #4c78ff;
    padding: 10px 25px;
    background-color: #bcbdbf;
    cursor: default;

    .tooltiptext {
      visibility: hidden;
      width: 42em;
      top: 105%;
      left: 73%;
      margin-left: -60px;
      background-color: black;
      color: #fff;
      text-align: center;
      padding: 5px;
      border-radius: 6px;
      margin-top: 5px;
      position: absolute;
      z-index: 999;
    }
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  padding-top: 50px;
  //shoppingCart-left
  .shoppingCart-left {
    .shoppingCart-left-parent {
      .shoppingCart-left-heading {
        h2 {
          color: #000000;
          font-family: "gothammedium";
          font-size: 24px;
          margin: 0px 0px 20px;
        }
      }
    }
    //shoppingCart-left-inner
    .shoppingCart-left-inner {
      .theme-card {
        margin-bottom: 15px;
        .card-body {
          .shopping-item {
            h3 {
              color: #141516;
              font-size: 16px;
              font-family: "gothammedium";
              margin: 0px 0px 8px;
            }
            p {
              color: #000000;
              font-size: 12px;
              margin: 0px 0px 8px;
              line-height: 16px;
              font-weight: 400;
              font-family: "gothammedium";
            }
          }
          .shopping-item-button {
            display: flex;
            align-items: center;
            .theme-btn {
              margin-right: 15px;
            }
          }
          .item-delete-icon {
            ion-icon {
              color: #4c78ff;
              font-size: 18px;
            }
          }
          //shopping-price
          .shopping-price {
            margin-bottom: 15px;
            //request-insurance-btn
            .request-insurance-btn {
              .theme-btn {
                width: 100%;
                --padding-end: 15px;
                --padding-start: 15px;
              }
            }
            .shopping-price-box {
              //background-color: #fff;
              padding: 10px 10px;
              border-radius: 6px;
              display: flex;
              margin-bottom: 2px;
              ion-checkbox {
                margin-right: 10px;
                --background: transparent;
                --border-color: #4c78ff;
                --border-radius: 100%;
                --background-checked: #4c78ff;
                min-width: 18px;
              }
              p {
                color: #000000;
                font-size: 13px;
                margin: 0px;
                max-width: 500px;
                line-height: 16px;
                font-family: "gothammedium";
                span {
                  font-size: 10px;
                }
              }
            }
          }

          //shopping-price-info
          .shopping-price-info {
            p {
              color: #000000;
              font-size: 10px;
              margin: 0px;
              max-width: 500px;
              line-height: 12px;
              font-family: "gothammedium";
              text-align: right;
              a {
                color: #4c78ff;
              }
            }
          }

          //shopping-item-form
          .shopping-item-form {
            //medicine-item
            .medicine-item {
              .form-group {
                ion-label {
                  color: #000000;
                  margin-bottom: 10px;
                }
                .medicine-item-list {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  a {
                    background-color: #a8a8c233;
                    color: #8b8ba9;
                    display: block;
                    border-radius: 6px;
                    font-size: 12px;
                    font-family: "gothammedium";
                    padding: 10px 8px;
                    width: 80px;
                    text-align: center;
                    margin: 0px 5px 5px;
                    min-width: 80px;
                    &.active {
                      background-color: #4c78ff1a;
                      color: #4c78ff;
                    }
                  }
                }
              }
            }
          }
        }
      }

      //medication-add-btn
      .medication-add-btn {
        .theme-btn {
          min-width: 250px;
        }
      }

      //cart-info-pera
      .cart-info-pera {
        p {
          color: #4c78ff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          font-family: "gothambook";
        }
      }
    }
  }

  //shopping-summary
  .shopping-summary {
    .shopping-summary-head {
      border-bottom: 1px solid #6d6d6d33;
      padding-bottom: 10px;
      margin-bottom: 10px;
      h2 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 24px;
        margin: 0px;
      }
    }
    .shopping-summary-body {
      table {
        width: 100%;
        tr {
          td {
            color: #000000;
            font-family: "gothammedium";
            font-size: 14px;
            margin: 0px;
            padding: 5px 0px;
            &:nth-last-child(1) {
              text-align: right;
            }
            &.green {
              color: #2ed5a3 !important;
            }
          }
        }
        tbody {
          tr {
            td {
            }
          }
        }
        tfoot {
          tr {
            td {
            }
          }
        }
      }
    }
    .shopping-summary-bottom {
      .promocode-detail {
        margin-bottom: 10px;
        .promocode-input {
          .form-group {
            ion-label {
            }
            .form-control {
            }
          }
          .promocode-apply-btns {
            .theme-btn {
            }
          }
        }
        .promocode-apply-detail {
          border-radius: 6px;
          border: 1px solid #a9a9c3;
          padding: 10px 20px;
          text-align: center;
          margin-bottom: 2px;
          p {
            color: #8b8ba9;
            margin: 0px;
            font-size: 14px;
            font-family: "gothammedium";
          }
        }
        .promocode-remove {
          a {
            display: block;
            text-align: right;
            font-size: 12px;
            margin: 0px;
            max-width: 500px;
            line-height: 16px;
            font-family: "gothammedium";
          }
        }
      }
    }
  }
}

.price-match-submit-btns {
  max-width: 345px;
  margin: auto;
}
