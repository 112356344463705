.header {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 0px 0px;
  z-index: 999;
  &:after {
    background-image: none;
  }
  //ion-toolbar {
  //  --background: #fff;
  //  --padding-bottom: 10px;
  //  --padding-end: 0px;
  //  --padding-start: 0px;
  //  --padding-top: 10px;
  //}
  ion-grid {
    ion-row {
      align-items: center;
      ion-col {
        .header-logo {
          ion-img {
            width: 100%;
            max-width: 140px;
          }
        }

        .header-left {
          display: flex;
          align-items: center;
          //meds-btn
          .meds-btn {
            margin-right: 10px;
            .theme-btn {
              font-size: 12px;
              --padding-end: 12px;
              --padding-start: 12px;
            }
          }

          //help
          .header-help {
            margin-right: 15px;
            .helpdrop {
              position: relative;
              .helpdrop-top {
                color: #4c78ff;
                font-weight: 600;
                font-size: 14px;
                font-family: "gothammedium";
                display: flex;
                align-items: center;
                ion-icon {
                  font-size: 18px;
                  margin-left: 5px;
                }
              }
              .helpdrop-inner {
                //display: none;
                min-width: 185px;
                background-color: #fff;
                position: absolute;
                top: 40px;
                left: 0;
                z-index: 1000;
                box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                padding: 20px 15px;
                //questions-help
                .questions-help {
                  margin-bottom: 20px;
                  .questions-help-heading {
                    text-align: center;
                    margin-bottom: 20px;
                    h3 {
                      color: #000000;
                      font-size: 14px;
                      margin: 0;
                      line-height: 16px;
                      font-weight: 400;
                      font-family: "gothambook";
                    }
                  }
                  .questions-help-mid {
                    .theme-btn {
                      margin-bottom: 10px;
                      display: block;
                      padding: 10px 10px;
                      white-space: nowrap;
                      text-align: center;
                    }
                  }
                }
                //faq-help
                .faq-help {
                  .faq-help-heading {
                    margin-bottom: 5px;
                    h3 {
                      color: #141516;
                      font-size: 18px;
                      font-family: "gothammedium";
                      margin: 0px;
                    }
                  }
                  .faq-help-mid {
                    margin-bottom: 20px;
                    a {
                      color: #4c78ff;
                      font-size: 13px;
                      line-height: 16px;
                      font-family: "gothammedium";
                      display: block;
                      margin-bottom: 6px;
                      &:nth-last-child(1) {
                        margin-bottom: 0px;
                      }
                    }
                  }
                  .faq-help-bottom {
                    .theme-btn {
                      margin-bottom: 10px;
                      display: block;
                      padding: 10px 10px;
                      white-space: nowrap;
                      text-align: center;
                    }
                  }
                }
              }
              // &:hover {
              //   .helpdrop-inner {
              //     display: block;
              //   }
              // }
            }
          }
          //header-searchbar
          .header-searchbar {
            position: relative;
            width: 100%;
            ion-searchbar {
              --border-radius: 8px;
              --box-shadow: none;
              --icon-color: #8b8ba9;
              --placeholder-color: #c0c1d4;
              --background: #fff;
              --color: #000;
              font-family: "gothammedium";
              position: relative;
              padding: 0px;
              .searchbar-input-container {
                border: 1px solid rgba(139, 139, 169, 0.3);
                border-radius: 8px;
                .searchbar-input {
                  padding-inline-start: 10px;
                  font-size: 14px;
                }
                .searchbar-search-icon {
                  left: initial;
                  right: 16px;
                }
                .searchbar-clear-button {
                  background-color: #fff;
                }
              }
              ion-spinner {
                position: absolute;
                top: 10px;
                right: 12px;
                z-index: 1;
                color: #141516;
                width: 24px;
                height: 24px;
                min-width: 18px;
                background-color: #fff;
              }
            }
            .search-drop-list {
              background-color: #fff;
              box-shadow: 0px 3px 25px #00000029;
              padding: 10px;
              border-radius: 4px;
              position: absolute;
              width: 100%;
              z-index: 3;
              left: 0;
              top: 60px;
              .phone-or-email {
                display: contents;
              }
              a {
                color: #4c78ff;
                font-size: 13px;
                line-height: 16px;
                font-family: "gothammedium";
                display: block;
                margin-bottom: 6px;
                &:nth-last-child(1) {
                  margin-bottom: 0px;
                }
              }
              p {
                color: #000000;
                font-size: 14px;
                margin: 0;
                line-height: 16px;
                font-weight: 400;
                font-family: "gothammedium";
              }
            }
          }
        }

        .header-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          //flex-wrap: wrap;
          .header-support {
            margin-right: 15px;
            .supportdrop {
              position: relative;
              .supportdrop-top {
                color: #4c78ff;
                font-weight: 600;
                font-size: 14px;
                font-family: "gothammedium";
                display: flex;
                align-items: center;
                ion-icon {
                  font-size: 18px;
                  margin-left: 5px;
                }
              }
              .supportdrop-inner {
                display: none;
                min-width: 185px;
                background-color: #fff;
                position: absolute;
                top: 18px;
                left: 0;
                z-index: 1000;
                box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                padding: 20px 15px;
                a {
                  color: #141516;
                  font-weight: 100;
                  font-size: 14px;
                  font-family: "gothammedium";
                  display: block;
                  padding: 10px 0px;
                  border-bottom: 1px solid #6d6d6d33;
                  &:hover {
                    color: #4c78ff;
                  }
                  &:nth-last-child(1) {
                    border-bottom: 0px;
                    padding: 10px 0px 0px;
                  }
                  &:nth-child(1) {
                    padding: 0px 0px 10px;
                  }
                }
              }

              &:hover {
                .supportdrop-inner {
                  display: block;
                }
              }
            }
          }

          .header-right-btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            //flex-wrap: wrap;
          }

          .header-buttons {
            ion-button {
              margin-right: 10px;
              font-size: 12px;
            }
          }
          .header-count-link {
            display: flex;
            align-items: center;
            a {
              color: #4c78ff;
              font-weight: 600;
              font-size: 14px;
              font-family: "gothammedium";
              display: flex;
              align-items: center;
              margin: 0px 10px;
              .icon-count {
                position: relative;
                ion-icon {
                  font-size: 20px;
                  margin-right: 5px;
                }
                span {
                  position: absolute;
                  background-color: #2ed4a3;
                  color: #fff;
                  border-radius: 100%;
                  font-size: 9px;
                  width: 16px;
                  height: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  top: -9px;
                  right: 0;
                  font-family: "gothambook";
                  font-weight: 100;
                }
              }
            }
          }
        }
      }
    }
  }

  //header-coman-droupdown start
  .header-coman-droupdown {
    .header-comandrop {
      position: relative;
      .header-comandrop-top {
        color: #4c78ff;
        font-weight: 600;
        font-size: 14px;
        font-family: "gothammedium";
        display: flex;
        align-items: center;
        ion-icon {
          font-size: 18px;
          margin-right: 5px;
        }
      }
      .header-comandrop-inner {
        display: none;
        min-width: 230px;
        background-color: #fff;
        position: absolute;
        top: 18px;
        left: 0;
        z-index: 1000;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px 15px;
        a {
          color: #141516;
          font-weight: 100;
          font-size: 14px;
          font-family: "gothammedium";
          display: block;
          padding: 10px 0px;
          border-bottom: 1px solid #6d6d6d33;
          &:hover {
            color: #4c78ff;
          }
          &:nth-last-child(1) {
            border-bottom: 0px;
            padding: 10px 0px 0px;
          }
          &:nth-child(1) {
            padding: 0px 0px 10px;
          }
        }
        .theme-btn {
          margin-top: 15px;
        }
      }

      &:hover {
        .header-comandrop-inner {
          display: block;
        }
      }
    }
  }
  //header-coman-droupdown end
}

@media only screen and (max-width: 767px) {
  .header {
    position: relative;
    ion-grid {
      ion-row {
        ion-col {
          .header-logo {
            text-align: center;
            ion-img {
              margin: 0px auto;
              max-width: 150px;
            }
          }
          .header-left {
            justify-content: center;
            flex-wrap: wrap;
            .header-searchbar {
              ion-searchbar {
                padding-inline-end: 0px;
                padding-inline-start: 0px;
                padding: 0px;
                height: 50px;
              }
            }
          }

          .header-right {
            justify-content: center;
            flex-wrap: wrap;
            // flex-flow: column;
            // align-items: center;
            .header-support {
              margin-right: 0px;
              //margin-bottom: 12px;
            }
            .header-right-btns {
              .header-buttons {
                margin: 5px 0px;
                display: flex;
                ion-button {
                  margin: 0px 3px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    //header-coman-droupdown start
    .header-coman-droupdown {
      .header-comandrop {
        .header-comandrop-inner {
          min-width: 210px;
        }
      }
    }
    //header-coman-droupdown end
  }
}

@media only screen and (max-width: 445px) {
  .header {
    ion-grid {
      ion-row {
        ion-col {
          .header-left {
            //help
            .header-help {
              .helpdrop {
                .helpdrop-inner {
                  left: auto;
                  right: -75px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 446px) {
  .header {
    ion-grid {
      ion-row {
        ion-col {
          .header-left {
            //help
            .header-help {
              .helpdrop {
                .helpdrop-inner {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .header {
    position: relative;
    ion-grid {
      ion-row {
        align-items: center;
        ion-col {
          .header-logo {
            ion-img {
              // margin: 0px auto;
            }
          }
          .header-left {
            justify-content: center;
          }
          .header-right {
            justify-content: center;
          }
        }
      }
    }
  }
}
