.checkoutPrescription-page {
  padding-top: 50px;
  .errorInput {
    border-color: red;
  }
  .form-group {
    .form-control {
      color: #000;
      .native-input[disabled] {
        opacity: 1;
      }
    }
  }

  .theme-card {
    margin-bottom: 20px;
  }
  //prescriptions-box
  .prescriptions-box {
    .prescriptions-heading {
      margin-bottom: 20px;
      h3 {
        color: #000000;
        font-family: "gothammedium";
        font-size: 16px;
        margin: 0px;
      }
    }
    .prescriptions-body {
      &.alert-body{
        text-align: center;
      }
      .alert-title{
        color: #4C78FF;
        font-weight: 500;
        font-size: 20px;
        font-family: "gothammedium";
        margin-top: 32px;
      }
      .prescriptions-dropbox {
        .prescriptions-dropbox-heading {
          margin-bottom: 15px;
          a {
            background-color: #76f9d233;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 6px;
            h3 {
              margin: 0px;
              color: #000000;
              font-family: "gothammedium";
              font-size: 14px;
              span {
                font-size: 13px;
                color: #141516;
                margin-left: 20px;
              }
            }
            ion-icon {
              color: #141516;
              font-size: 18px;
            }
          }
        }
        .prescriptions-dropbox-body {
          //check-dropbox
          .check-dropbox {
            .check-dropbox-head {
              background-color: #fff;
              padding: 10px 10px;
              border-radius: 6px;
              margin-bottom: 20px;
              .check-dropbox-head-inner {
                display: flex;
                ion-checkbox {
                  margin-right: 10px;
                  --background: #fff;
                  --border-color: #4c78ff;
                  --border-radius: 100%;
                  --background-checked: #4c78ff;
                  min-width: 18px;
                  width: 18px;
                  height: 18px;
                }
                p {
                  color: #000000;
                  font-size: 13px;
                  margin: 0px;
                  max-width: 500px;
                  line-height: 16px;
                  font-family: "gothammedium";
                }
              }
              //pharmacySearch-result
              .pharmacySearch-result {
                .pharmacySearch-result-item {
                  padding-top: 15px;
                  h3 {
                    color: #000000;
                    font-size: 14px;
                    margin: 0px 0px 8px;
                    max-width: 500px;
                    line-height: 16px;
                    font-family: "gothammedium";
                  }
                  p {
                    color: #000000;
                    font-size: 12px;
                    margin: 0px 0px 8px;
                    max-width: 500px;
                    line-height: 16px;
                    font-family: "gothammedium";
                  }
                  a {
                    color: #4c78ff;
                    font-size: 13px;
                    margin: 0px 0px 8px;
                    max-width: 500px;
                    line-height: 16px;
                    font-family: "gothammedium";
                  }
                }
              }
            }
            .check-dropbox-body {
              background-color: #edf1ff;
              padding: 10px 10px;
              border-radius: 6px;
              margin-bottom: 20px;
              //doctors-info
              .doctors-info {
                .doctors-info-top, .doctors-info-message {
                  margin-bottom: 15px;
                  p {
                    color: #000000;
                    font-size: 13px;
                    margin: 0px;
                    max-width: 500px;
                    line-height: 16px;
                    font-family: "gothammedium";
                  }
                }
                .doctors-info-body {
                  border: 1px solid #8b8ba94d;
                  border-radius: 6px;
                  padding: 10px;
                  margin-bottom: 15px;
                  .doctors-detail {
                    ion-label {
                      color: #8b8ba9;
                      font-size: 13px;
                      margin: 0px 0px 5px 0px;
                      line-height: 16px;
                      display: block;
                      font-family: "gothammedium";
                    }
                    p {
                      color: #000000;
                      font-size: 13px;
                      margin: 0px;
                      max-width: 500px;
                      line-height: 16px;
                      font-family: "gothammedium";
                    }
                  }
                }
                .doctors-info-bottom {
                  .theme-btn {
                  }
                }
              }

              //form-group
              .form-group {
              }
              // /perta-brif
              .perta-brif {
                color: #000000;
                font-size: 12px;
                margin: 2px 0px 8px;
                max-width: 500px;
                line-height: 16px;
                font-family: "gothammedium";
              }
              //check-dropbox-bottom
              .check-dropbox-bottom {
                .theme-btn {
                }
              }
            }
          }
        }
      }
    }
  }

  //parentform-button
  .parentform-button {
    .theme-btn {
      display: block;
      max-width: 250px;
      margin-bottom: 10px;
    }
  }
}

//Responsive scss
@media only screen and (max-width: 767px) {
  .checkoutPrescription-page {
    padding: 30px 0px;
    ion-grid {
      ion-row {
        padding-left: 0px;
        padding-right: 0px;
        ion-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
    //prescriptions-box
    .prescriptions-box {
      .prescriptions-body {
        .prescriptions-dropbox {
          .prescriptions-dropbox-heading {
            a {
              h3 {
                display: flex;
                flex-flow: column;
                span {
                  margin-left: 0px;
                  margin-top: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
