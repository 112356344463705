//static-page
.static-page {
  padding: 40px 0px;
  .static-page-heading {
    margin-bottom: 25px;
    h2 {
      color: #141516;
      font-size: 28px;
      font-family: "gothammedium";
      margin: 0px;
      letter-spacing: 0.5px;
    }
  }
  .static-page-inner {
    h1 {
      color: #141516;
      font-size: 24px;
      font-family: "gothammedium";
      margin: 15px 0px 15px;
      letter-spacing: 0.5px;
    }

    h2 {
      color: #141516;
      font-size: 22px;
      font-family: "gothammedium";
      margin: 15px 0px 15px;
      letter-spacing: 0.5px;
    }

    h3 {
      color: #141516;
      font-size: 20px;
      font-family: "gothammedium";
      margin: 15px 0px 15px;
      letter-spacing: 0.5px;
    }

    h4 {
      color: #141516;
      font-size: 18px;
      font-family: "gothammedium";
      margin: 15px 0px 15px;
      letter-spacing: 0.5px;
    }

    h5 {
      color: #141516;
      font-size: 16px;
      font-family: "gothammedium";
      margin: 15px 0px 15px;
      letter-spacing: 0.5px;
    }

    h6 {
      color: #141516;
      font-size: 15px;
      font-family: "gothammedium";
      margin: 15px 0px 15px;
      letter-spacing: 0.5px;
    }

    p {
      color: #000000;
      font-size: 13px;
      margin: 0px 0px 15px;
      line-height: 20px;
      font-weight: 100;
      font-family: "gothammedium";
      letter-spacing: 0.5px;
      &.static-text-grey {
        color: #8b8ba9;
      }
    }
    a {
      color: #4c78ff;
      font-size: 13px;
      margin: 0px 0px 15px;
      line-height: 20px;
      font-weight: 100;
      font-family: "gothammedium";
      letter-spacing: 0.5px;
    }
  }
}
