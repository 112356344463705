.checkoutReview-page {
  padding-top: 50px;
  .theme-card {
    margin-bottom: 20px;
    .card-body {
      padding: 10px;
    }
  }

  //parentform
  .parentform {
    .parentform-button {
      .theme-btn {
        min-width: 250px;
      }
    }
  }

  //methodBox
  .methodBox {
    .shippingMethod-item {
      background-color: #f7f8fb;
      &:nth-last-child(1) {
        margin-bottom: 0px !important;
      }
    }
  }

  //patientInfo
  .patientInfo {
    &.methodBox {
      .shippingMethod-item {
        background-color: #fff;
        padding: 0px;
        .shippingMethod-left {
          h3 {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  //shipping
  .shipping {
    &.methodBox {
      .shippingMethod-item {
        background-color: #fff;
        padding: 0px;
        margin-bottom: 20px;
        .shippingMethod-left {
          h3 {
            margin-bottom: 5px;
          }
        }
        .shippingMethod-right {
          p {
            margin-top: 15px;
          }
        }
      }
    }
  }

  //payment
  .payment {
    &.methodBox {
      .shippingMethod-item {
        background-color: #fff;
        padding: 0px;
        margin-bottom: 20px;
      }
    }
  }
}

//responsive
@media only screen and (max-width: 767px) {
  .checkoutReview-page {
    padding: 30px 0px;
    ion-grid {
      ion-row {
        padding-left: 0px;
        padding-right: 0px;
        ion-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }

    //methodBox
    .methodBox {
      .shippingMethod-item {
        .shippingMethod-left {
          margin-bottom: 2px;
        }
        .shippingMethod-right {
          margin-left: 0px;
        }
      }
    }

    //shipping
    .shipping {
      &.methodBox {
        .shippingMethod-item {
          .shippingMethod-right {
            p {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .checkoutReview-page {
    //methodBox
    .methodBox {
      .shippingMethod-item {
        .shippingMethod-left {
          margin-bottom: 2px;
        }
        .shippingMethod-right {
          margin-left: 0px;
        }
      }
    }

    //shipping
    .shipping {
      &.methodBox {
        .shippingMethod-item {
          .shippingMethod-right {
            p {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}
