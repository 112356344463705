.dashboardTab-content {
  padding: 40px 0px;
  .dashboardTab-heading {
    text-align: center;
    margin-bottom: 20px;
    h2 {
      color: #000000;
      font-family: "gothammedium";
      margin: 0px;
    }
  }
  .dashboardTab-inner {
    position: relative;
    margin-top: 30px;
    border-bottom: 4px solid #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      color: #141516;
      font-size: 16px;
      padding: 15px;
      font-family: "gothammedium";
      position: relative;
      margin-bottom: 5px;
      &.active {
        color: #4c78ff;
        &:after {
          content: "";
          bottom: -10px;
          left: 0;
          background-color: #4c78ff;
          height: 8px;
          width: 100%;
          position: absolute;
          border-radius: 25px;
          z-index: 1;
        }
      }
    }
  }
}

// @media only screen and (max-width: 767px) {
//   .sidebar-content {
//     display: none;
//   }
// }

@media only screen and (max-width: 767px) {
  .dashboardTab-content {
    .dashboardTab-inner {
      justify-content: center;
      border: 0px;
      a {
        font-size: 14px;
        padding: 12px;
        margin-bottom: 5px;
      }
    }
  }
}
