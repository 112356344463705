.unauthorizedUser-page {
  padding: 50px 0px;
  min-height: 82%;
  display: flex;
  align-items: center;
  justify-content: center;
  .theme-card {
    max-width: 500px;
    margin: 0px auto;
    .card-body {
      .unauthorizedUser-inner {
        text-align: center;
        .unauthorizedUser-img {
          ion-img {
            width: 100%;
            max-width: 150px;
            margin: 0px auto;
          }
        }
        h2 {
          color: #000000;
          font-family: "gothammedium";
          font-size: 18px;
          line-height: 20px;
        }
        p {
          color: #000000;
          font-size: 12px;
          margin: 0px 0px 10px;
          line-height: 14px;
          font-weight: 400;
          font-family: "gothammedium";
          text-align: center;
        }
        a {
          color: #4c78ff;
          font-size: 13px;
          margin: 0px;
          line-height: 16px;
          font-weight: 400;
          font-family: "gothammedium";
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .unauthorizedUser-page {
    padding: 30px 0px;
  }
}
